/************* NAME OF ROUTES ********************* */
/* -------------- AMBASSADOR ROUTES ----------------- */
// Login
export const NAVIGATE_TO_LOGINPAGE = '/login';
export const NAVIGATE_TO_REGISTRATIONPAGE = '/registration';
export const NAVIGATE_TO_EMAILVERIFICATIONPAGE = '/verify-email';
export const NAVIGATE_TO_FORGETPASSWORDPAGE = '/forget-password';

// Dashboard
export const NAVIGATE_TO_DASHBOARD = '/';

// Token manage
export const NAVIGATE_TO_TOKENPAGE = '/tokens';
export const NAVIGATE_TO_VIEWREFERENCEPAGE = '/view-reference';

// Transactions
export const NAVIGATE_TO_PACKAGEPAGE = '/packages';
export const NAVIGATE_TO_TRANSACTIONHISTORYPAGE = '/transactions-history';
export const NAVIGATE_TO_PAYMENTREQUESTPAGE = '/payment-request';

// Integration
export const NAVIGATE_TO_APIACCESSPAGE = '/api-access';
export const NAVIGATE_TO_LOGSPAGE = '/logs';
export const NAVIGATE_TO_DEVELOPERGUIDEPAGE = '/developer-guide';

//Profile
export const NAVIGATE_TO_PROFILEPAGE = '/profile';

//Support
export const NAVIGATE_TO_SUPPORTPAGE = '/support';
export const NAVIGATE_TO_INFOPAGE = '/info';

// Error Pages
export const ERROR_404_PAGE ='*'