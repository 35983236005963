import { FINGERSCAN, STATUS } from "../../constant";

const INITIAL_STATE = {
    dashboardStatus: STATUS.DEFAULT,
    dashboardResult: null,
    dashboardErrorMessage: "",
}

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        /* ------ DASHBOARD REDUCERS ----- */
        case FINGERSCAN.DASHBOARD_LOADING:
            return {
                ...state,
                dashboardStatus: STATUS.LOADING
            }
        case FINGERSCAN.DASHBOARD_SUCCESS:
            return {
                ...state,
                dashboardStatus: STATUS.SUCCESS,
                dashboardResult: payload,
                dashboardErrorMessage: ""
            }
        case FINGERSCAN.DASHBOARD_FAILURE:
            return {
                ...state,
                dashboardStatus: STATUS.ERROR,
                dashboardResult: null,
                dashboardErrorMessage: payload
            }
        case FINGERSCAN.DASHBOARD_RESET:
            return {
                ...state,
                dashboardStatus: STATUS.DEFAULT,
                dashboardResult: null,
                dashboardErrorMessage: ""
            }

        default:
            return state;
    }
}