import "./App.css";
// import PublicRoute from './route/Mainroute'
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AnimatePresence, useForceUpdate } from "framer-motion";

import { useDispatch, connect } from "react-redux";


//import MainRoute from './route/Mainroute';
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import RoutesContainer from "./route/Routes";
import { useEffect } from "react";
import i18n from "./i18n/i18n";
import { logoutRequest, tokenRequest, tokenReset } from "./store/actions";
import { STATUS } from "./constant";

// FUNCTION TO CHECK TOKEN
const parseJwt = (token) => {
  try {
    let jwtFirstPart = token.split(".")[1];
    // console.log(JSON.parse(atob(jwtFirstPart)));
    return JSON.parse(atob(jwtFirstPart));
  } catch (error) {
    console.log(error);
    return null;
  }
};

// MAIN TO RENDER ROUTES AND PAGES
const App = ({
  loginStatus,
  loginResult,
  loginErrorMessage,
  accessToken,
  refreshTokenStatus,
  refreshResult,
  refreshTokenError
}) => {

  const dispatch = useDispatch()

  useEffect(() => {
    if (loginStatus === STATUS.SUCCESS) {
      checkTokenValidity(parseJwt(accessToken));
    }
  },[loginStatus])

  useEffect(() => {
    if (refreshTokenStatus === STATUS.ERROR) {
      // console.log('REFREST ERROR:',refreshTokenError)
      if (refreshTokenError === "Authentication required") {
        toast.warn("User access timeout please login");
        dispatch(logoutRequest());
      }
      else if (refreshTokenError === "Token not found"){
          toast.error(refreshTokenError)
          dispatch(logoutRequest());
      }
      dispatch(tokenReset());
    }
  },[refreshTokenStatus])

  const checkTokenValidity = (decodedJwt) => {
    if (!decodedJwt) {
      toast.warn("Not enabled User");
      dispatch(logoutRequest());
      return;
    }

    if (decodedJwt.exp * 1000 < Date.now()) {
      toast.warn("User access timeout please login");
      dispatch(logoutRequest());
      const data = {
        "email": loginResult.message.email,
        "RefreshToken": loginResult.message.authentication.refreshToken
      }
      dispatch(tokenRequest(data))
    }
    return;
  };

  return (
    <>
      <AnimatePresence mode="wait">
        <RoutesContainer loginStatus={loginStatus} />
      </AnimatePresence>
      <ToastContainer
        autoClose={3000}
        draggable={false}
        position="top-right"
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
      />
    </>
  );
};

const mapStateToProps = ({ auth, user }) => {
  const {
    loginStatus: loginStatus,
    loginResult: loginResult,
    loginErrorMessage: loginErrorMessage,
    accessToken,

    tokenStatus: refreshTokenStatus,
    tokenResult: refreshResult,
    tokenErrorMessage: refreshTokenError
  } = auth;

  const { userTypes: userType } = user;

  return {
    loginStatus,
    loginResult,
    loginErrorMessage,
    accessToken,
    refreshTokenStatus,
    refreshResult,
    refreshTokenError,

    userType,
  };
};
export default connect(mapStateToProps, {})(App);


