import { FINGERSCAN } from "../../constant";


/* ------- LOGIN ACTIONS ------ */
export function loginRequest(data) {
    return {
        type: FINGERSCAN.LOGIN_REQUEST,
        payload: {
            data
        }
    }
    
}

export function loginReset (){
    return {
        type: FINGERSCAN.LOGIN_RESET
    }
}

/* ------- TOKEN ACTIONS ------ */
export function tokenRequest(data) {
    return {
        type: FINGERSCAN.TOKEN_REQUEST,
        payload: {
            data
        }
    }
    
}

export function tokenReset (){
    return {
        type: FINGERSCAN.TOKEN_RESET
    }
}

/* ------ REGISTRATIONS ACTIONS ------ */
export function registrationRequest(data){
    return {
        type: FINGERSCAN.REGISTRATION_REQUEST,
        payload: {
            data
        }
    }
}

export function registrationReset(){
    return {
        type: FINGERSCAN.REGISTRATION_RESET
    }
} 

/* ------ VERIFY EMAIL ACTIONS ------- */
export function verifyEmailRequest(data){
    return{
        type: FINGERSCAN.VERIFY_EMAIL_REQUEST,
        payload: {
            data
        }
    }
}

export function verifyEmailReset(){
    return {
        type: FINGERSCAN.VERIFY_EMAIL_RESET
    }
}

/* ------ FORGET PASSWORD ACTIONS ------ */
export function forgetPasswordRequest(data){
    return {
        type: FINGERSCAN.FORGET_PASSWORD_REQUEST,
        payload: {
            data
        }
    }
}

export function forgetPasswordReset(){
    return {
        type: FINGERSCAN.FORGET_PASSWORD_RESET
    }
}

/* ------ CHANGE PASSWORD ACTIONS ------ */
export function changePasswordRequest(token, data){
    return {
        type: FINGERSCAN.CHANGE_PASSWORD_REQUEST,
        payload: {
            token,
            data
        }
    }
}

export function changePasswordReset(){
    return {
        type: FINGERSCAN.CHANGE_PASSWORD_RESET
    }
}

/* ----- LOGOUT ACTION ------- */
export function logoutRequest (){
    return {
        type: FINGERSCAN.LOGIN_RESET
    }
}

