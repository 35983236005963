/* ...... Base API Url ..... */
export const API_BASE = 'https://scanapi.tbridge.africa'
// export const API_BASE = "https://7b89-102-209-39-243.ngrok-free.app";


/* ...... Auth endpoints ..... */
export const LOGIN_URL = "/api/Auth/generate_token";
export const TOKEN_URL = "/api/Auth/refresh-token";
export const REGISTRATION_URL = "/api/Auth/registration";
export const VERIFY_EMAIL_URL = "/api/Auth/verify_email";
export const RESEND_EMAIL_URL = "/api/Auth/request_verification_code";
export const FORGET_PASSWORD_URL = "/api/Auth/forgot_password";
export const CHANGE_PASSWORD_URL = "/api/PortalServices/change_Password";

/* ...... Dashboard endpoints ..... */
export const DASHBOARD_URL = "";

/* ...... reference endpoints ..... */
export const CREATE_REFERENCE_URL = "/api/PortalServices/generate_Ref";
export const ACTIVE_REFERENCE_URL = "";
export const REFERENCE_LIST_URL = "/api/PortalServices/references";
export const VIEW_REFERENCE_URL = "/api/PortalServices/fetch_Response_Data";

/* ...... payment and package endpoints ..... */
export const PACKAGES_URL = "/api/PortalServices/get_Packages";
export const PACKAGE_DETAILS_URL = "/api/PortalServices/get_package_details";
export const PACKAGE_SUBSCRIBE_URL = "/api/PortalServices/subscribe_package";
export const PROFESSIONAL_PACKAGE_URL = "";
export const TRANSACTION_LIST_URL = "/api/PortalServices/get_transaction_history";

/* ...... Integrations endpoints ..... */
export const VIEW_API_URL = "/api/PortalServices/my_callback_details";
export const API_URL = "/api/PortalServices/add_callback";
export const LOGS_URL = "";