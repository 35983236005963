import React, { Fragment, useEffect, useState } from "react";
import { Typography, Box, Button, Card, CardContent, ButtonGroup } from "@mui/joy";
import { toast } from 'react-toastify';
import { Main, PageTitle } from "../../../components";
import { useTranslation } from "react-i18next";
import { InfoOutlined } from "@mui/icons-material";
import { NAVIGATE_TO_SUPPORTPAGE } from "../../../route/types";
import {
    requestPhpCode,
    receiveCPlusCode,
    receiveJavaCode,
    receivePhpCode,
    requestCPlusCode,
    requestJavaCode
} from "./code";

const CodeSnippet = ({ title, code, Selection, lang }) => {

    return (
        <Card sx={{backgroundColor: '#181818', p: 0}}>
            <CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        p: 1
                    }}>
                    <Typography level="title-sm" sx={{color: 'lightyellow'}} gutterBottom>{title}</Typography>
                    <ButtonGroup
                        size="sm"
                        color="neutral"
                        sx={{
                            borderRadius: 4,
                        }}>
                        <Button variant={lang === 'java' ? "solid" : "outlined"} sx={{color: 'white'}} onClick={() => Selection('java')}>Java</Button>
                        <Button variant={lang === 'php' ? "solid" : "outlined"} sx={{color: 'white'}} onClick={() => Selection('php')}>Php</Button>
                        <Button variant={lang === 'c++' ? "solid" : "outlined"} sx={{color: 'white'}} onClick={() => Selection('c++')}>C++</Button>
                    </ButtonGroup>
                </Box>

                <Typography component="div" style={{
                    backgroundColor: '#121212',
                    borderRadius: 5,
                    // pt: '10px',
                    whiteSpace: 'pre-wrap', // Ensures line breaks are preserved
                    wordBreak: 'break-word', // Prevents long strings from breaking the layout
                    fontFamily: 'monospace',
                    color: 'greenyellow'
                }}>
                    {code}
                </Typography>
            </CardContent>
        </Card>
    );
};

const RequestGuide = () => {
    const [lang, setLang] = useState('java');
    const [code, setCode] = useState('')


    useEffect(() => {
        if (lang === 'java') {
            setCode(requestJavaCode);
        }
        else if (lang === 'php') {
            setCode(requestPhpCode);
        }
        else if (lang === 'c++') {
            setCode(requestCPlusCode);
        }
        else {
            setCode(requestJavaCode);
        }
    }, [lang, code])

    const guideStep = [
        {
            title: 'Step 01',
            desc: "In 'Integration Tab', provide your callback url and choose the finger to be captured and it is format then submit and you will receive 'CLIENT ID' and 'SECRET KEY'."
        },
        {
            title: 'Step 02',
            desc: "Use the api endpoint you have been provided in documentation for request reference, in that endpoint you will require to pass 'Basic authentication' which is 'Client Id' and 'Secret Key' that you get when submit your callback url"
        },
        {
            title: "Step 03",
            desc: "After successful request, your user will receive 'Reference' through the channel as agreed with us which can be through SMS or Email."
        }
    ]

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mt: 2,
            }}>
            <Typography level="title-lg">Request Reference</Typography>
            <Typography level="body-md">To request reference you need to follow this steps</Typography>

            {
                guideStep.map((item, index) => (
                    <Box key={index}>
                        <Typography level="title-md">{item.title}</Typography>
                        <Typography level="body-md">{item.desc}</Typography>
                    </Box>
                ))
            }

            <CodeSnippet title={"Request reference code"} code={code} Selection={setLang} lang={lang} />
        </Box>
    );
}

const ReceiveGuide = () => {
    const [lang, setLang] = useState('java');
    const [code, setCode] = useState('')


    useEffect(() => {
        if (lang === 'java') {
            setCode(receiveJavaCode);
        }
        else if (lang === 'php') {
            setCode(receivePhpCode);
        }
        else if (lang === 'c++') {
            setCode(receiveCPlusCode);
        }
        else {
            setCode(receiveJavaCode);
        }
    }, [lang, code])

    const guideStep = [
        {
            title: 'Step 01',
            desc: "In 'Integration Tab', provide your callback url and choose the finger to be captured and it is format then submit and you will receive 'CLIENT ID' and 'SECRET KEY'."
        },
        {
            title: 'Step 02',
            desc: "Through the 'callback url' you submitted, you will receive responce with token for authentication through the header, response body with Json format...."
        },
        {
            title: "Step 03",
            desc: "After successful request, your user will receive 'Reference' through the channel as agreed with us which can be through SMS or Email."
        }
    ]

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mt: 2,
            }}>
            <Typography level="title-lg">Receive Data Guide</Typography>
            <Typography level="body-md">To receive data you need to follow this steps</Typography>

            {
                guideStep.map((item, index) => (
                    <Box key={index}>
                        <Typography level="title-md">{item.title}</Typography>
                        <Typography level="body-md">{item.desc}</Typography>
                    </Box>
                ))
            }

            <CodeSnippet title={"Receive data code"} code={code} Selection={setLang} lang={lang} />
        </Box>
    );
}

const DeveloperGuidePage = () => {

    const { t } = useTranslation()

    const [selectGuide, setSelectGuide] = useState("request");


    return (
        <Box>
            <PageTitle title={t("developerGuide.title")} />

            <Typography
                level="body-md"
                variant="soft"
                color="danger"
                startDecorator={<InfoOutlined />}
                sx={{
                    // fontFamily: 'Roboto Mono Variable', 
                    px: 1,
                    py: 0.8,
                    mb: 2,
                    borderRadius: 'sm',
                    // backgroundColor: 'danger' 
                }}
            >
                {t("developerGuide.desc") + " "}
            </Typography>

            {/* guide select buttons */}
            <Box sx={{
                gap: 1,
                display: 'flex'
            }}>
                <Button
                    variant={selectGuide === "request" ? "solid" : "outlined"}
                    color={selectGuide === "request" ? "neutral" : "neutral"}
                    onClick={() => setSelectGuide("request")}
                    sx={{
                        borderRadius: 50,
                        px: 1,
                    }}
                >
                    {t("developerGuide.chooseBtn.request")}
                </Button>
                <Button
                    variant={selectGuide === "receive" ? "solid" : "outlined"}
                    color={selectGuide === "receive" ? "neutral" : "neutral"}
                    onClick={() => setSelectGuide("receive")}
                    sx={{
                        borderRadius: 50,
                        px: 1,
                    }}>{
                        t("developerGuide.chooseBtn.receive")}
                </Button>
            </Box>

            {selectGuide === "request" ? <RequestGuide /> : <ReceiveGuide />}
        </Box>

    )
}

export default DeveloperGuidePage