import { call, put, takeLatest } from 'redux-saga/effects';
import { FINGERSCAN } from "../../constant";
import errorMessage from '../../utils/error.json';
import { doAPI, doFetchLogs, doViewAPI } from '../../service/integrations';


function* apiTask(action) {
    try {
        yield put({ type: FINGERSCAN.API_LOADING });

        const { payload } = action;

        const res = yield call(doAPI, payload.token, payload.data);

        if (res.status == 200) {
            yield put({
                type: FINGERSCAN.API_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.statusCode] : res.message = errorMessage[1000];
            yield put({
                type: FINGERSCAN.API_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.statusCode] : errorMessage[4000];
        yield put({
            type: FINGERSCAN.API_FAILURE,
            payload: errMsg
        })
    }
}

function* viewApiTask(action) {
    try {
        yield put({ type: FINGERSCAN.VIEW_API_LOADING });

        const { payload } = action;

        const res = yield call(doViewAPI, payload.token, payload.data);

        if (res.status == 200) {
            yield put({
                type: FINGERSCAN.VIEW_API_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.statusCode] : res.message = errorMessage[1000];
            yield put({
                type: FINGERSCAN.VIEW_API_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.statusCode] : errorMessage[4000];
        yield put({
            type: FINGERSCAN.VIEW_API_FAILURE,
            payload: errMsg
        })
    }
}

function* logsTask(action) {
    try {
        yield put({ type: FINGERSCAN.LOGS_LOADING });

        const { payload } = action;

        const res = yield call(doFetchLogs, payload.token, payload.data);

        if (res.status == 200) {
            yield put({
                type: FINGERSCAN.LOGS_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.statusCode] : res.message = errorMessage[1000];
            yield put({
                type: FINGERSCAN.LOGS_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.statusCode] : errorMessage[4000];
        yield put({
            type: FINGERSCAN.LOGS_FAILURE,
            payload: errMsg
        })
    }
}

function* integrationSaga() {
    yield takeLatest(FINGERSCAN.API_REQUEST, apiTask);
    yield takeLatest(FINGERSCAN.VIEW_API_REQUEST, viewApiTask);
    yield takeLatest(FINGERSCAN.LOGS_REQUEST, logsTask);
}

export default integrationSaga;