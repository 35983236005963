const REQUEST = 'REQUEST';
const LOADING = 'LOADING';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
const RESET = 'RESET';

const suffixTypes = [REQUEST, LOADING, SUCCESS, FAILURE, RESET];

function createRequestTypes(prefix = '', bases, suffixes = suffixTypes) {
    const req = {};
    bases.forEach((base) => {
        suffixes.forEach((suffix) => {
            req[`${base}_${suffix}`] = `${prefix}_${base}_${suffix}`;
        });
    });
    return req;
}

//sava application expected api states
export const FINGERSCAN = createRequestTypes('FINGERSCAN',
    [
        // ..... Authentications ..... //
        'LOGIN',
        'TOKEN',
        'VERIFY_EMAIL',
        'RESEND_EMAIL',
        'REGISTRATION',
        'FORGET_PASSWORD',
        'CHANGE_PASSWORD',

        // .... dashboard .... //
        'DASHBOARD',

        // .... reference .... //
        'CREATE_REFERENCE',
        'REFERENCE_LIST',
        'ACTIVE_REFERENCE',
        'VIEW_REFERENCE',

        // ... payments and packages ... //
        'PACKAGES',
        'PACKAGE_DETAILS',
        'PACKAGE_SUBSCRIBE',
        'PROFESSIONAL_PACKAGE',
        'TRANSACTION_LIST',

        // ...  integrations ... //
        'API',
        'VIEW_API',
        'LOGS'

    ], suffixTypes);

export const ACTION_RESET_APP_STATE = "RESET_STATE";