import * as React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import GlobalStyles from "@mui/joy/GlobalStyles";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import WalletRoundedIcom from "@mui/icons-material/WalletRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import CurrentExchangeRoundIcon from "@mui/icons-material/CurrencyExchangeOutlined";

// import ColorSchemeToggle from '../../utils/ColorSchemeToggle';
import { closeSidebar } from "../../utils";
import { logoutRequest } from "../../store/actions";
import {
  NAVIGATE_TO_DASHBOARD,
  NAVIGATE_TO_APIACCESSPAGE,
  NAVIGATE_TO_LOGSPAGE,
  NAVIGATE_TO_TOKENPAGE,
  NAVIGATE_TO_PACKAGEPAGE,
  NAVIGATE_TO_TRANSACTIONHISTORYPAGE,
  NAVIGATE_TO_VIEWREFERENCEPAGE,
  NAVIGATE_TO_DEVELOPERGUIDEPAGE,
  NAVIGATE_TO_PAYMENTREQUESTPAGE,
} from "../../route/types";
import { useDispatch } from "react-redux";
import { FolderOutlined, KeyboardArrowDown, LinkOutlined, ManageAccountsOutlined, PagesRounded, SpeedOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function Toggler({
  defaultExpanded = false,
  renderToggle,
  route,
  children,
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  route: boolean;
  renderToggle: (params: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}) {
  const [open, setOpen] = React.useState(defaultExpanded);

  React.useEffect(() => {
    // Set the initial state based on the condition
    setOpen(defaultExpanded || route);
  }, [defaultExpanded, route]);

  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: open ? "1fr" : "0fr",
          transition: "0.2s ease",
          "& > *": {
            overflow: "hidden",
          },
        }}>
        {open && children}
      </Box>
    </React.Fragment>
  );
}


const ListItemComponent = ({ route, path, action, props }) => {
  const location = useLocation();
  const navigate = useNavigate()

  const navTo = () => {
    navigate(route)
    action()
    // alert('i am navigate')
  }
  return (
    <ListItemButton
      role="menuitem"
      selected={path ? true : false}
      disabled={path ? true : false}
      sx={{
        alignItems: 'center',
      }}
      onClick={navTo}>

      <ListItemContent>
        <Typography
          level="body-sm"
          sx={{
            fontWeight: path ? 'bold' : undefined,
            fontFamily: 'Roboto',
            color: path ? "white" : 'rgba(159, 166, 173, 50)',
            '--List-gap': '0px'
          }}>
          {props.title}
        </Typography>
      </ListItemContent>
    </ListItemButton>
  );
};

const ListTitle = ({ route, icon, title, path }) => {
  return (
    <ListItem component="div" startAction={icon}>
      <Link to={route} style={{ textDecoration: 'none' }}>
        <Typography
          level="body-xs"
          sx={{
            textTransform: 'uppercase',
            fontFamily: 'Roboto, monospace',
            color: path ? 'white' : 'rgb(159, 166, 173)',
          }}>
          {title}
        </Typography>
      </Link>
    </ListItem >
  )
}

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const logOut = () => {
    // navigate(NAVIGATE_TO_LOGINPAGE)
    dispatch(logoutRequest());
    // dispatch(resetUserType());
  }

  //MAIN
  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: "fixed", md: "fixed" },
        backgroundColor: '#0F1226',
        transform: {
          xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
          md: "none",
        },
        transition: "transform 0.4s ease, width 0.4s",
        zIndex: 9991,
        // height: "100dvh",
        minHeight: '100dvh',
        width: "var(--Sidebar-width)",
        top: 0,
        p: 2,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
        pt: {
          xs: 'calc(22px + var(--Header-height))',
          sm: 'calc(25px + var(--Header-height))',
          md: 'calc(80px + var(--Header-height))',
        },
      }}>
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Sidebar-width": "220px",
            [theme.breakpoints.up("lg")]: {
              "--Sidebar-width": "240px",
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: 9998,
          top: 0,
          left: 0,
          width: "100vw",
          // height: "100vh",
          minHeight: '100vh',
          opacity: "var(--SideNavigation-slideIn)",
          backgroundColor: "var(--joy-palette-background-backdrop)",
          transition: "opacity 0.4s",
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
            lg: "translateX(-100%)",
          },
        }}
        onClick={() => closeSidebar()}
      />

      {/* Search Input if needed later */}
      {/* <Input size="sm" startDecorator={<SearchRoundedIcon />} placeholder="Search" /> */}

      <Box
        sx={{
          // minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          pl: '10px',
          // display: "flex",
          // flexDirection: "column",
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}>
        <List
          size="sm"
          sx={(theme) => ({
            // Gatsby colors
            '--joy-palette-primary-plainColor': '#8a4baf',
            '--joy-palette-neutral-plainHoverBg': '#00000050',
            '--joy-palette-neutral-plainActiveBg': 'transparent',
            '--joy-palette-primary-plainHoverBg': 'gray',
            '--joy-palette-primary-plainActiveBg': 'transparent',
            [theme.getColorSchemeSelector('dark')]: {
              '--joy-palette-text-secondary': '#635e69',
              '--joy-palette-primary-plainColor': '#d48cff',
            },

            '--List-insetStart': '32px',
            '--ListItem-paddingY': '8px',
            '--ListItem-paddingRight': '16px',
            '--ListItem-paddingLeft': '21px',
            '--ListItem-startActionWidth': '0px',
            '--ListItem-startActionTranslateX': '-50%',

            [`& .${listItemButtonClasses.root}`]: {
              borderLeftColor: 'divider',
            },
            [`& .${listItemButtonClasses.root}.${listItemButtonClasses.selected}`]: {
              borderLeftColor: 'white',
            },
            '& [class*="startAction"]': {
              color: 'var(--joy-palette-text-tertiary)',
            },
          })}
        >

          <ListTitle
            title={t("sidebar.home")}
            icon={<HomeRoundedIcon sx={{ color: 'goldenrod' }} />}
            route={NAVIGATE_TO_DASHBOARD}
            path={location.pathname === NAVIGATE_TO_DASHBOARD} />

          {/* home */}
          <ListItemComponent
            route={NAVIGATE_TO_DASHBOARD}
            path={location.pathname === NAVIGATE_TO_DASHBOARD}
            action={() => null}
            props={{
              title: t("sidebar.dashboard"),
              icon: <SpeedOutlined />
            }}
          />

          <ListTitle
            title={t("sidebar.reference")}
            icon={<PagesRounded sx={{ color: 'green' }} />}
            route={NAVIGATE_TO_TOKENPAGE}
            path={location.pathname === NAVIGATE_TO_TOKENPAGE || location.pathname === NAVIGATE_TO_VIEWREFERENCEPAGE} />

          {/* Reference */}
          <ListItemComponent
            route={NAVIGATE_TO_TOKENPAGE}
            path={location.pathname === NAVIGATE_TO_TOKENPAGE || location.pathname === NAVIGATE_TO_VIEWREFERENCEPAGE}
            action={() => null}
            props={{
              title: t("sidebar.referenceCreate"),
              icon: <ManageAccountsOutlined />
            }}
          />

          <ListTitle
            title={t("sidebar.transaction")}
            icon={<WalletRoundedIcom sx={{ color: 'brown' }} />}
            route={NAVIGATE_TO_PACKAGEPAGE}
            path={location.pathname === NAVIGATE_TO_PACKAGEPAGE || location.pathname === NAVIGATE_TO_TRANSACTIONHISTORYPAGE || location.pathname === NAVIGATE_TO_PAYMENTREQUESTPAGE} />

          {/* Packages */}
          <ListItemComponent
            route={NAVIGATE_TO_PACKAGEPAGE}
            path={location.pathname === NAVIGATE_TO_PACKAGEPAGE || location.pathname === NAVIGATE_TO_PAYMENTREQUESTPAGE}
            action={() => null}
            props={{
              title: t("sidebar.package"),
              icon: <CurrentExchangeRoundIcon />
            }}
          />

          {/* Transaction History */}
          <ListItemComponent
            route={NAVIGATE_TO_TRANSACTIONHISTORYPAGE}
            path={location.pathname ===  NAVIGATE_TO_TRANSACTIONHISTORYPAGE}
            action={() => null}
            props={{
              title: t("sidebar.transactionHistory"),
              icon: <FolderOutlined />
            }}
          />

          {/* <ListTitle
            title={t("sidebar.integrations")}
            icon={<LinkOutlined sx={{ color: 'tomato' }} />}
            route={NAVIGATE_TO_APIACCESSPAGE}
            path={location.pathname === NAVIGATE_TO_APIACCESSPAGE || location.pathname === NAVIGATE_TO_LOGSPAGE} /> */}

          {/* Intergration API */}
          {/* <DropdowmList props={{ title: 'Intergration', icon: <LinkOutlined /> }}> */}

          {/* API Access */}
          {/* <ListItemComponent
            route={NAVIGATE_TO_APIACCESSPAGE}
            path={location.pathname === NAVIGATE_TO_APIACCESSPAGE}
            action={() => null}
            props={{
              title: t("sidebar.APIcallback"),
              icon: null
            }}
          /> */}

          {/* Developer Guide */}
          {/* <ListItemComponent
            route={NAVIGATE_TO_DEVELOPERGUIDEPAGE}
            path={location.pathname === NAVIGATE_TO_DEVELOPERGUIDEPAGE}
            action={() => null}
            props={{
              title: t("sidebar.developerGuide"),
              icon: null
            }}
          /> */}

          {/* logs */}
          {/* <ListItemComponent
            route={NAVIGATE_TO_LOGSPAGE}
            path={location.pathname === NAVIGATE_TO_LOGSPAGE}
            action={() => null}
            props={{
              title: t("sidebar.logs"),
              icon: null
            }}
          /> */}
          {/* </DropdowmList> */}

          <Divider sx={{ my: "10px" }} />
          <ListItemComponent
            route="#"
            path={null}
            action={logOut}
            props={{
              title: t("sidebar.logout"),
              icon: <LogoutRoundedIcon />
            }}
          />
        </List>
      </Box>
    </Sheet >
  );
};

// export default connect(mapStateToProps, {})(Sidebar);
export default Sidebar
