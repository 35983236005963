import { call, put, takeLatest } from 'redux-saga/effects';
import { FINGERSCAN } from "../../constant";
import errorMessage from '../../utils/error.json';
import { doFetchPackage, dofetchPackageDetails, doFetchTransactionsList, doPackageSubscribe, doProfessionalPackage } from '../../service/payments';


function* packagesTask(action) {
    try {
        yield put({ type: FINGERSCAN.PACKAGES_LOADING });

        const { payload } = action;

        const res = yield call(doFetchPackage, payload.token, payload.data);

        if (res.status == 200) {
            yield put({
                type: FINGERSCAN.PACKAGES_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.statusCode] : res.message = errorMessage[1000];
            yield put({
                type: FINGERSCAN.PACKAGES_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.statusCode] : errorMessage[4000];
        yield put({
            type: FINGERSCAN.PACKAGES_FAILURE,
            payload: errMsg
        })
    }
}



function* packageDetailsTask(action) {
    try {
        yield put({ type: FINGERSCAN.PACKAGE_DETAILS_LOADING });

        const { payload } = action;

        const res = yield call(dofetchPackageDetails, payload.token, payload.data);

        if (res.status == 200) {
            yield put({
                type: FINGERSCAN.PACKAGE_DETAILS_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.statusCode] : res.message = errorMessage[1000];
            yield put({
                type: FINGERSCAN.PACKAGE_DETAILS_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.statusCode] : errorMessage[4000];
        yield put({
            type: FINGERSCAN.PACKAGE_DETAILS_FAILURE,
            payload: errMsg
        })
    }
}

function* packageSubscribeTask(action) {
    try {
        yield put({ type: FINGERSCAN.PACKAGE_SUBSCRIBE_LOADING });

        const { payload } = action;

        const res = yield call(doPackageSubscribe, payload.token, payload.data);

        if (res.status == 200) {
            yield put({
                type: FINGERSCAN.PACKAGE_SUBSCRIBE_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.statusCode] : res.message = errorMessage[1000];
            yield put({
                type: FINGERSCAN.PACKAGE_SUBSCRIBE_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.statusCode] : errorMessage[4000];
        yield put({
            type: FINGERSCAN.PACKAGE_SUBSCRIBE_FAILURE,
            payload: errMsg
        })
    }
}
function* professionalPackageTask(action) {
    try {
        yield put({ type: FINGERSCAN.PROFESSIONAL_PACKAGE_LOADING });

        const { payload } = action;

        const res = yield call(doProfessionalPackage, payload.token, payload.data);

        if (res.status == 200) {
            yield put({
                type: FINGERSCAN.PROFESSIONAL_PACKAGE_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.statusCode] : res.message = errorMessage[1000];
            yield put({
                type: FINGERSCAN.PROFESSIONAL_PACKAGE_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.statusCode] : errorMessage[4000];
        yield put({
            type: FINGERSCAN.PROFESSIONAL_PACKAGE_FAILURE,
            payload: errMsg
        })
    }
}


function* transactionListTask(action) {
    try {
        yield put({ type: FINGERSCAN.TRANSACTION_LIST_LOADING });

        const { payload } = action;

        const res = yield call(doFetchTransactionsList, payload.token, payload.data);

        if (res.status == 200) {
            yield put({
                type: FINGERSCAN.TRANSACTION_LIST_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.statusCode] : res.message = errorMessage[1000];
            yield put({
                type: FINGERSCAN.TRANSACTION_LIST_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.statusCode] : errorMessage[4000];
        yield put({
            type: FINGERSCAN.TRANSACTION_LIST_FAILURE,
            payload: errMsg
        })
    }
}

function* paymentsSaga() {
    yield takeLatest(FINGERSCAN.PACKAGES_REQUEST, packagesTask);
    yield takeLatest(FINGERSCAN.TRANSACTION_LIST_REQUEST, transactionListTask);
    yield takeLatest(FINGERSCAN.PACKAGE_DETAILS_REQUEST, packageDetailsTask);
    yield takeLatest(FINGERSCAN.PACKAGE_SUBSCRIBE_REQUEST, packageSubscribeTask);
    yield takeLatest(FINGERSCAN.PROFESSIONAL_PACKAGE_REQUEST, professionalPackageTask);
}

export default paymentsSaga;