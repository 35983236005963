import { Link, useNavigate } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { toast } from "react-toastify";
import { STATUS } from "../../constant";
import React, { Fragment, useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import GlobalStyles from "@mui/joy/GlobalStyles";
import Sheet from "@mui/joy/Sheet";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import IconButton from "@mui/joy/IconButton";
import Avatar from "@mui/joy/Avatar";
import Tooltip from "@mui/joy/Tooltip";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import ListDivider from "@mui/joy/ListDivider";

import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";

import { toggleSidebar } from "../../utils/sideBarutils";
import { logoutRequest, resetUserType } from "../../store/actions";
import { useColorScheme } from "@mui/joy/styles";
import { NAVIGATE_TO_DASHBOARD, NAVIGATE_TO_LOGINPAGE, NAVIGATE_TO_PROFILEPAGE, NAVIGATE_TO_SUPPORTPAGE } from "../../route/types";
import image from "../../constant/image";
import { HelpOutlineRounded, Lock } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
} from "@mui/joy";
import LanguageMenu from "../molecules/LanguageMenu";
import { useTranslation } from "react-i18next";
// import ColorSchemeToggle from '../../utils/ColorSchemeToggle';

function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return <IconButton size="sm" variant="outlined" color="primary" />;
  }
  return (
    <Tooltip title="Change theme" variant="outlined">
      <IconButton
        id="toggle-mode"
        size="sm"
        variant="plain"
        color="neutral"
        sx={{ alignSelf: "center" }}
        onClick={() => {
          if (mode === "light") {
            setMode("dark");
          } else {
            setMode("light");
          }
        }}>
        {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
      </IconButton>
    </Tooltip>
  );
}

const Header = ({
  loginResult
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [name, setName] = useState("");

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (loginResult != null || loginResult != undefined) {
      setName(loginResult.message.name)
    }
  }, [loginResult])


  const logOut = () => {
    // navigate(NAVIGATE_TO_LOGINPAGE)
    // setTimeout(() => {
    //   dispatch(resetUserType());
    // }, 2000);
    dispatch(logoutRequest());
  };

  return (
    <Fragment>
      <Sheet
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "fixed",
          backgroundColor: 'background.surface',
          top: 0,
          width: "100vw",
          // bgcolor: "background.appcolor",
          // height: 'var(--Header-height)',
          zIndex: 9999,
          px: { xs: 1, md: 3 },
          py: { xs: 2, md: 2 },
          gap: 1,
          borderBottom: "1px solid",
          borderColor: "background.level1",
          boxShadow: "sm",
        }}>
        <GlobalStyles
          styles={(theme) => ({
            ":root": {
              "--Header-height": "52px",
              [theme.breakpoints.up("md")]: {
                "--Header-height": "0px",
              },
            },
          })}
        />

        {/* ------------------- MENU ICON ----------------------- */}
        <IconButton
          onClick={() => toggleSidebar()}
          variant="outlined"
          // color="neutral"
          size="sm"
          sx={{
            // borderColor: "whitesmoke",
            display: { xs: 'flex', md: 'none' }
          }}>
          <MenuIcon />
        </IconButton>

        {/* --------------------- Logo and App name ------------------- */}

        <Link to={NAVIGATE_TO_DASHBOARD} style={{ textDecoration: 'none' }}>
          <Box sx={{
            display: { xs: "none", md: "flex" },
            gap: 1,
            alignItems: "center"
          }}>
            <IconButton
              variant="soft"
              sx={{ backgroundColor: "whitesmoke" }}
              size="sm">
              <Avatar
                src={image.Images.icon}
                sx={{
                  maxWidth: "32px",
                  maxHeight: "32px",
                  backgroundColor: "transparent",
                }}
              />
            </IconButton>
            <Typography level="h4" sx={{ fontFamily: 'Roboto Mono Variable, monospace' }}>{t("intro.appName")}</Typography>
          </Box>
        </Link>


        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1.5,
            alignItems: "center",
          }}>

          {/* help and support */}
          <Tooltip title="Help & Support" variant="outlined">
            <IconButton
              size="sm"
              variant="plain"
              color="neutral"
              sx={{ alignSelf: "center" }}
              onClick={() => {
                navigate(NAVIGATE_TO_SUPPORTPAGE)
              }}>
              <HelpOutlineRounded />
            </IconButton>
          </Tooltip>

          <LanguageMenu change={undefined}/>

          {/* Change theme */}
          <ColorSchemeToggle />

          {/* --------------- Dropdown menu toggle  ---------- */}
          <Dropdown>
            <MenuButton
              variant="outlined"
              // color="secondary"
              size="sm"
              sx={{
                // maxWidth: "32px",
                gap: 1,
                // maxHeight: {xs: "32px", md: 'auto'},
                borderRadius: "9999999px",
                borderColor: 'background.appcolor'
              }}>
              <Avatar
                src={image.Images.userIcon}
                sx={{ maxWidth: "29px", maxHeight: "29px" }}
                size="sm"
              />
              {/* ------------ user name  -------------*/}
              <Typography level="title-sm" sx={{ display: { xs: 'none', md: 'flex' } }}>
                {name}
              </Typography>
            </MenuButton>

            {/* ---------------- Dropdown Menu ------------------- */}
            <Menu
              placement="bottom-end"
              size="sm"
              sx={{
                zIndex: "99999",
                p: 1,
                gap: 1,
                "--ListItem-radius": "var(--joy-radius-sm)",
              }}>

              {/* --------------- User profile ----------- */}
              <MenuItem >
                <Link to={NAVIGATE_TO_PROFILEPAGE} style={{ textDecoration: 'none' }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <Avatar
                      src={image.Images.userIcon}
                      sx={{ maxWidth: "29px", maxHeight: "29px" }}
                      size="sm"
                    />
                    <Box sx={{ ml: 1.5 }}>
                      <Typography level="title-sm" textColor="text.primary">
                        {name}
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </MenuItem>

              <Divider />

              {/* ------------- logout button ----------------- */}
              <MenuItem component="a" href="#" onClick={logOut}>
                <LogoutRoundedIcon />
                {t("header.logout")}
              </MenuItem>
            </Menu>
          </Dropdown>
        </Box>
      </Sheet>
    </Fragment>
  );
};

const mapStateToProps = ({ auth }) => {
  const { loginResult: loginResult } = auth

  return {
    loginResult
  }
}

export default connect(mapStateToProps, {})(Header);
