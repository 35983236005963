import { FINGERSCAN, STATUS } from "../../constant";

const INITIAL_STATE = {
    createReferenceStatus: STATUS.DEFAULT,
    createReferenceResult: null,
    createReferenceErrorMessage: "",

    activeReferenceStatus: STATUS.DEFAULT,
    activeReferenceResult: null,
    activeReferenceErrorMessage: "",

    referenceListStatus: STATUS.DEFAULT,
    referenceListResult: null,
    referenceListErrorMessage: "",

    viewReferenceStatus: STATUS.DEFAULT,
    viewReferenceResult: null,
    viewReferenceErrorMessage: ''
}

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        /* ------ CREATE_REFERENCE REDUCERS ----- */
        case FINGERSCAN.CREATE_REFERENCE_LOADING:
            return {
                ...state,
                createReferenceStatus: STATUS.LOADING
            }
        case FINGERSCAN.CREATE_REFERENCE_SUCCESS:
            return {
                ...state,
                createReferenceStatus: STATUS.SUCCESS,
                createReferenceResult: payload,
                createReferenceErrorMessage: ""
            }
        case FINGERSCAN.CREATE_REFERENCE_FAILURE:
            return {
                ...state,
                createReferenceStatus: STATUS.ERROR,
                createReferenceResult: null,
                createReferenceErrorMessage: payload
            }
        case FINGERSCAN.CREATE_REFERENCE_RESET:
            return {
                ...state,
                createReferenceStatus: STATUS.DEFAULT,
                createReferenceResult: null,
                createReferenceErrorMessage: ""
            }

        /* ------ ACTIVE_REFERENCE REDUCERS ------ */
        case FINGERSCAN.ACTIVE_REFERENCE_LOADING:
            return {
                ...state,
                activeReferenceStatus: STATUS.LOADING
            }
        case FINGERSCAN.ACTIVE_REFERENCE_SUCCESS:
            return {
                ...state,
                activeReferenceStatus: STATUS.SUCCESS,
                activeReferenceResult: payload,
                activeReferenceErrorMessage: ""
            }
        case FINGERSCAN.ACTIVE_REFERENCE_FAILURE:
            return {
                ...state,
                activeReferenceStatus: STATUS.ERROR,
                activeReferenceResult: null,
                activeReferenceErrorMessage: payload
            }
        case FINGERSCAN.ACTIVE_REFERENCE_RESET:
            return {
                ...state,
                activeReferenceStatus: STATUS.DEFAULT,
                activeReferenceResult: null,
                activeReferenceErrorMessage: ""
            }

        /* ------ REFERENCE LIST REDUCERS ------ */
        case FINGERSCAN.REFERENCE_LIST_LOADING:
            return {
                ...state,
                referenceListStatus: STATUS.LOADING
            }
        case FINGERSCAN.REFERENCE_LIST_SUCCESS:
            return {
                ...state,
                referenceListStatus: STATUS.SUCCESS,
                referenceListResult: payload,
                referenceListErrorMessage: ""
            }
        case FINGERSCAN.REFERENCE_LIST_FAILURE:
            return {
                ...state,
                referenceListStatus: STATUS.ERROR,
                referenceListResult: null,
                referenceListErrorMessage: payload
            }
        case FINGERSCAN.REFERENCE_LIST_RESET:
            return {
                ...state,
                referenceListStatus: STATUS.DEFAULT,
                referenceListResult: null,
                referenceListErrorMessage: ""
            }


        /* ------ REFERENCE LIST REDUCERS ------ */
        case FINGERSCAN.VIEW_REFERENCE_LOADING:
            return {
                ...state,
                viewReferenceStatus: STATUS.LOADING
            }
        case FINGERSCAN.VIEW_REFERENCE_SUCCESS:
            return {
                ...state,
                viewReferenceStatus: STATUS.SUCCESS,
                viewReferenceResult: payload,
                viewReferenceErrorMessage: ""
            }
        case FINGERSCAN.VIEW_REFERENCE_FAILURE:
            return {
                ...state,
                viewReferenceStatus: STATUS.ERROR,
                viewReferenceResult: null,
                viewReferenceErrorMessage: payload
            }
        case FINGERSCAN.VIEW_REFERENCE_RESET:
            return {
                ...state,
                viewReferenceStatus: STATUS.DEFAULT,
                viewReferenceResult: null,
                viewReferenceErrorMessage: ""
            }

        default:
            return state;
    }
}