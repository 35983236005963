import { Box, Divider, Typography } from "@mui/joy";
import React from "react";

const PageTitle = ({ title }) => {
    return (
        <Box sx={{ gap: 0.5, pb: 1, mb: 3 }} >
            <Typography level='title-lg' sx={{fontFamily: 'Roboto, monospace', fontSize: 24}}>{title}</Typography>
            <Divider component='div' sx={{ width: '30px', height: '2px', borderRadius: '20px', backgroundColor: 'primary.500' }} />
        </Box>
    )
}

export default PageTitle