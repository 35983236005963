import { FINGERSCAN } from "../../constant";


/* ------- CREATE_REFERENCE ACTIONS ------ */
export function createReferenceRequest(token, data) {
    return {
        type: FINGERSCAN.CREATE_REFERENCE_REQUEST,
        payload: {
            token,
            data
        }
    }

}

export function createReferenceReset() {
    return {
        type: FINGERSCAN.CREATE_REFERENCE_RESET
    }
}

/* ------ ACTIVE_REFERENCES ACTIONS ------ */
export function activeReferenceRequest(token, data) {
    return {
        type: FINGERSCAN.ACTIVE_REFERENCE_REQUEST,
        payload: {
            token,
            data
        }
    }
}

export function activeReferenceReset() {
    return {
        type: FINGERSCAN.ACTIVE_REFERENCE_RESET
    }
}

/* ------ REFERENCE LIST ACTIONS ------ */
export function referenceListRequest(token, data) {
    return {
        type: FINGERSCAN.REFERENCE_LIST_REQUEST,
        payload: {
            token,
            data
        }
    }
}

export function referenceListReset() {
    return {
        type: FINGERSCAN.REFERENCE_LIST_RESET
    }
}

/* ------ VIEW REFERENCE ACTIONS ------- */
export function viewReferenceRequest(token, data){
    return {
        type: FINGERSCAN.VIEW_REFERENCE_REQUEST,
        payload: {
            token,
            data
        }
    }
}

export function viewReferenceReset(){
    return {
        type: FINGERSCAN.VIEW_REFERENCE_RESET
    }
}
