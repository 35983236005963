import { FINGERSCAN } from "../../constant";


/* ------- PACKAGES ACTIONS ------ */
export function packagesRequest(token, data) {
    return {
        type: FINGERSCAN.PACKAGES_REQUEST,
        payload: {
            token,
            data
        }
    }

}

export function packagesReset() {
    return {
        type: FINGERSCAN.PACKAGES_RESET
    }
}

/* ------- PACKAGES DETAILS ACTIONS ------ */
export function packageDetailsRequest(token, data) {
    return {
        type: FINGERSCAN.PACKAGE_DETAILS_REQUEST,
        payload: {
            token,
            data
        }
    }

}

export function packagesDetailsReset() {
    return {
        type: FINGERSCAN.PACKAGE_DETAILS_RESET
    }
}

/* ------- PACKAGES SUBSCRIBE ACTIONS ------ */
export function packageSubscribeRequest(token, data) {
    return {
        type: FINGERSCAN.PACKAGE_SUBSCRIBE_REQUEST,
        payload: {
            token,
            data
        }
    }

}

export function packagesSubscribeReset() {
    return {
        type: FINGERSCAN.PACKAGE_SUBSCRIBE_RESET
    }
}

/* ------- PROFESSIONAL PACKAGES ACTIONS ------ */
export function professionalPackageRequest(token, data) {
    return {
        type: FINGERSCAN.PROFESSIONAL_PACKAGE_REQUEST,
        payload: {
            token,
            data
        }
    }

}

export function professionalPackageReset() {
    return {
        type: FINGERSCAN.PROFESSIONAL_PACKAGE_RESET
    }
}

/* ------ TRANSACTION_LISTS ACTIONS ------ */
export function transactionListRequest(token, data) {
    return {
        type: FINGERSCAN.TRANSACTION_LIST_REQUEST,
        payload: {
            token,
            data
        }
    }
}

export function transactionListReset() {
    return {
        type: FINGERSCAN.TRANSACTION_LIST_RESET
    }
}

