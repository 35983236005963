import { ACTIVE_REFERENCE_URL, CREATE_REFERENCE_URL, REFERENCE_LIST_URL, VIEW_REFERENCE_URL } from "../../constant";
import { resourceRequest } from "../calls";

export function doCreateReference(token, data){
    return resourceRequest(token, CREATE_REFERENCE_URL, data);
}

export function doFetchActiveReference(token, data){
    return resourceRequest(token, ACTIVE_REFERENCE_URL, data);
}

export function doFetchReferenceList(token, data){
    return resourceRequest(token, REFERENCE_LIST_URL, data);
}

export function doViewReference(token, data){
    return resourceRequest(token, VIEW_REFERENCE_URL, data);
}