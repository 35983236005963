import { FINGERSCAN, STATUS } from "../../constant";

const INITIAL_STATE = {
    packagesStatus: STATUS.DEFAULT,
    packagesResult: null,
    packagesErrorMessage: "",

    packageDetailsStatus: STATUS.DEFAULT,
    packageDetailsResult: null,
    packageDetailsErrorMessage: "",

    packageSubscribeStatus: STATUS.DEFAULT,
    packageSubscribeResult: null,
    packageSubscribeErrorMessage: "",

    professionalPackageStatus: STATUS.DEFAULT,
    professionalPackageResult: null,
    professionalPackageErrorMessage: "",

    transactionListStatus: STATUS.DEFAULT,
    transactionListResult: null,
    transactionListErrorMessage: "",

    referenceListStatus: STATUS.DEFAULT,
    referenceListResult: null,
    referenceListErrorMessage: "",
}

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        /* ------ PACKAGES REDUCERS ----- */
        case FINGERSCAN.PACKAGES_LOADING:
            return {
                ...state,
                packagesStatus: STATUS.LOADING
            }
        case FINGERSCAN.PACKAGES_SUCCESS:
            return {
                ...state,
                packagesStatus: STATUS.SUCCESS,
                packagesResult: payload,
                packagesErrorMessage: ""
            }
        case FINGERSCAN.PACKAGES_FAILURE:
            return {
                ...state,
                packagesStatus: STATUS.ERROR,
                packagesResult: null,
                packagesErrorMessage: payload
            }
        case FINGERSCAN.PACKAGES_RESET:
            return {
                ...state,
                packagesStatus: STATUS.DEFAULT,
                packagesResult: null,
                packagesErrorMessage: ""
            }

        /* ------ PACKAGE_DETAILS REDUCERS ----- */
        case FINGERSCAN.PACKAGE_DETAILS_LOADING:
            return {
                ...state,
                packageDetailsStatus: STATUS.LOADING
            }
        case FINGERSCAN.PACKAGE_DETAILS_SUCCESS:
            return {
                ...state,
                packageDetailsStatus: STATUS.SUCCESS,
                packageDetailsResult: payload,
                packageDetailsErrorMessage: ""
            }
        case FINGERSCAN.PACKAGE_DETAILS_FAILURE:
            return {
                ...state,
                packageDetailsStatus: STATUS.ERROR,
                packageDetailsResult: null,
                packageDetailsErrorMessage: payload
            }
        case FINGERSCAN.PACKAGE_DETAILS_RESET:
            return {
                ...state,
                packageDetailsStatus: STATUS.DEFAULT,
                packageDetailsResult: null,
                packageDetailsErrorMessage: ""
            }

        /* ------ PACKAGE_SUBSCRIBE REDUCERS ----- */
        case FINGERSCAN.PACKAGE_SUBSCRIBE_LOADING:
            return {
                ...state,
                packageSubscribeStatus: STATUS.LOADING
            }
        case FINGERSCAN.PACKAGE_SUBSCRIBE_SUCCESS:
            return {
                ...state,
                packageSubscribeStatus: STATUS.SUCCESS,
                packageSubscribeResult: payload,
                packageSubscribeErrorMessage: ""
            }
        case FINGERSCAN.PACKAGE_SUBSCRIBE_FAILURE:
            return {
                ...state,
                packageSubscribeStatus: STATUS.ERROR,
                packageSubscribeResult: null,
                packageSubscribeErrorMessage: payload
            }
        case FINGERSCAN.PACKAGE_SUBSCRIBE_RESET:
            return {
                ...state,
                packageSubscribeStatus: STATUS.DEFAULT,
                packageSubscribeResult: null,
                packageSubscribeErrorMessage: ""
            }

        /* ------ PROFESSIONAL_PACKAGE REDUCERS ----- */
        case FINGERSCAN.PROFESSIONAL_PACKAGE_LOADING:
            return {
                ...state,
                professionalPackageStatus: STATUS.LOADING
            }
        case FINGERSCAN.PROFESSIONAL_PACKAGE_SUCCESS:
            return {
                ...state,
                professionalPackageStatus: STATUS.SUCCESS,
                professionalPackageResult: payload,
                professionalPackageErrorMessage: ""
            }
        case FINGERSCAN.PROFESSIONAL_PACKAGE_FAILURE:
            return {
                ...state,
                professionalPackageStatus: STATUS.ERROR,
                professionalPackageResult: null,
                professionalPackageErrorMessage: payload
            }
        case FINGERSCAN.PROFESSIONAL_PACKAGE_RESET:
            return {
                ...state,
                professionalPackageStatus: STATUS.DEFAULT,
                professionalPackageResult: null,
                professionalPackageErrorMessage: ""
            }


        /* ------ TRANSACTION_LIST REDUCERS ------ */
        case FINGERSCAN.TRANSACTION_LIST_LOADING:
            return {
                ...state,
                transactionListStatus: STATUS.LOADING
            }
        case FINGERSCAN.TRANSACTION_LIST_SUCCESS:
            return {
                ...state,
                transactionListStatus: STATUS.SUCCESS,
                transactionListResult: payload,
                transactionListErrorMessage: ""
            }
        case FINGERSCAN.TRANSACTION_LIST_FAILURE:
            return {
                ...state,
                transactionListStatus: STATUS.ERROR,
                transactionListResult: null,
                transactionListErrorMessage: payload
            }
        case FINGERSCAN.TRANSACTION_LIST_RESET:
            return {
                ...state,
                transactionListStatus: STATUS.DEFAULT,
                transactionListResult: null,
                transactionListErrorMessage: ""
            }


        default:
            return state;
    }
}