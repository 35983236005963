import React, { Fragment, useEffect, useState } from "react";
import { Typography, Box, Card, Avatar, Button, FormControl, FormLabel, Input, Divider, IconButton } from "@mui/joy";
import { toast } from 'react-toastify';
import { LoadingView, Main, PageTitle } from "../../../components";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { STATUS } from "../../../constant";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { changePasswordRequest, changePasswordReset } from "../../../store/actions";

const passwordPros = {
    data: '',
    show: false
}

const ProfilePage = ({
    accessToken,
    loginStatus,
    loginResult,
    loginErrorMessage,

    changePasswordStatus,
    changePasswordResult,
    changePasswordErrorMessage
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [username, setUserName] = useState('');
    const [useremail, setUserEmail] = useState('');
    const [useraddress, setUserAddress] = useState('');

    const [newPass, setNewPass] = useState(passwordPros);
    const [oldPass, setOldPass] = useState(passwordPros);
    const [confirmPass, setConfirmPass] = useState(passwordPros);


    useEffect(() => {
        if (loginStatus === STATUS.SUCCESS) {
            setUserName(loginResult.message.name);
            setUserEmail(loginResult.message.email);
            setUserAddress('Dar es Salaam, Tanzania')
        }

        if (changePasswordStatus === STATUS.SUCCESS) {
            toast.success(t("profile.success"))
            setNewPass(passwordPros);
            setOldPass(passwordPros)
            setConfirmPass(passwordPros)
            dispatch(changePasswordReset());
        }
        else if (changePasswordStatus === STATUS.ERROR) {
            toast.error(changePasswordErrorMessage)
            dispatch(changePasswordReset());
        }
    }, [changePasswordStatus, loginStatus])

    const onChangePass = (event) => {
        event.preventDefault();

        if (newPass && confirmPass && oldPass) {
            const data = {
                "userId": 0,
                "oldPassword": oldPass.data,
                "newPassword": newPass.data,
                "confirmPassword": confirmPass.data
            }

            dispatch(changePasswordRequest(accessToken, data))
        } else {
            toast.warn("Please fill all fields")
        }
    }

    const styles = {
        input: {
            color: 'white',
            borderColor: 'white',
            backgroundColor: 'transparent',
            borderRadius: 4,
            '&::before': {
                display: 'none',
            },
            '&:focus-within': {
                borderColor: 'green',
                outline: '2px solid var(--Input-focusedHighlight)',
                outlineOffset: '2px',
                outlineColor: 'yellowgreen'
            },
        },
        label: {
            color: "whitesmoke"
        },

    }


    const check_loading = () => {
        if (changePasswordStatus === STATUS.LOADING) {
            return true
        }
        else {
            return false
        }
    }

    return (
        <Box>
            <PageTitle title={t("profile.title")} />
            <LoadingView loading={check_loading()} />
            <Box sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
            }}>
                <Card
                    variant="plain"
                    size="sm"
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        boxShadow: 'md',
                        p: 0,
                        borderRadius: 0,
                        background: 'background.surface',
                        maxWidth: 800,
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: { xs: "auto", md: '50%' },
                            px: 2,
                            py: 4,
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: 1
                        }}>
                        <Typography level="title-lg">{t("dashboard.myDetails")}</Typography>

                        <Avatar sx={{ width: '150px', height: '150px' }} />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                            <Typography level="title-md">{username}</Typography>
                            <Typography level="body-sm">{useremail}</Typography>
                            <Typography level="body-sm">{useraddress}</Typography>
                        </Box>
                    </Box>
                    <Box
                        component="form"
                        onSubmit={onChangePass}
                        sx={{
                            display: "flex",
                            flexDirection: 'column',
                            width: { xs: "auto", md: '50%' },
                            boxShadow: '-10px 0px 20px rgba(0, 0, 0, 0.5)',
                            px: 2,
                            py: 4,
                            justifyContent: 'flex-start',
                            backgroundColor: '#0F1226',
                            top: "-50px",
                            gap: 2
                        }}>
                        <Typography level="title-md" sx={{ color: 'white', textAlign: 'center' }}>{t("profile.subTitle")}</Typography>

                        <FormControl required sx={{ flex: 1 }}>
                            <FormLabel sx={styles.label}>{t("profile.oldPass")}</FormLabel>
                            <Input
                                value={oldPass.data}
                                onChange={(e) => setOldPass((prev) => ({ ...prev, data: e.target.value }))}
                                placeholder={t("profile.oldPassPlaceholder")}
                                type={oldPass.show ? "text" : "password"}
                                sx={styles.input}
                                endDecorator={
                                    <IconButton onClick={() => setOldPass((prev) => ({ ...prev, show: !prev.show }))}>
                                        {oldPass.show ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                    </IconButton>
                                } />
                        </FormControl>

                        <Divider sx={{ backgroundColor: 'white' }} />

                        <FormControl required sx={{ flex: 1 }}>
                            <FormLabel sx={styles.label}>{t("profile.newPass")}</FormLabel>
                            <Input
                                value={newPass.data}
                                onChange={(e) => setNewPass((prev) => ({ ...prev, data: e.target.value }))}
                                placeholder={t("profile.newPassPlaceholder")}
                                type={newPass.show ? "text" : "password"}
                                sx={styles.input}
                                endDecorator={
                                    <IconButton onClick={() => setNewPass((prev) => ({ ...prev, show: !prev.show }))}>
                                        {newPass.show ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                    </IconButton>
                                } />
                        </FormControl>

                        <FormControl required sx={{ flex: 1 }}>
                            <FormLabel sx={styles.label}>{t("profile.confirmPass")}</FormLabel>
                            <Input
                                value={confirmPass.data}
                                onChange={(e) => setConfirmPass((prev) => ({ ...prev, data: e.target.value }))}
                                placeholder={t("profile.confirmPassPlaceholder")}
                                type={confirmPass.show ? "text" : "password"}
                                sx={styles.input}
                                endDecorator={
                                    <IconButton onClick={() => setConfirmPass((prev) => ({ ...prev, show: !prev.show }))}>
                                        {confirmPass.show ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                    </IconButton>
                                } />
                        </FormControl>

                        <Button
                            type="submit"
                            color="success"
                            sx={{ mt: 2 }}>
                            {t("profile.submit")}
                        </Button>

                    </Box>
                </Card>
            </Box>

        </Box>




    )
}

const mapStateToProps = ({ auth }) => {
    const {
        accessToken,

        loginStatus,
        loginResult,
        loginErrorMessage,

        changePasswordStatus,
        changePasswordResult,
        changePasswordErrorMessage,
    } = auth

    return {
        accessToken,

        loginStatus,
        loginResult,
        loginErrorMessage,

        changePasswordStatus,
        changePasswordResult,
        changePasswordErrorMessage
    }


}

export default connect(mapStateToProps, {})(ProfilePage)