import { PACKAGE_DETAILS_URL, PACKAGE_SUBSCRIBE_URL, PACKAGES_URL, PROFESSIONAL_PACKAGE_URL, TRANSACTION_LIST_URL } from "../../constant";
import { resourceRequest } from "../calls";

export function doFetchPackage(token, data){
    return resourceRequest(token, PACKAGES_URL, data);
}

export function dofetchPackageDetails(token, data){
    return resourceRequest(token, PACKAGE_DETAILS_URL, data);
}

export function doPackageSubscribe(token, data){
    return resourceRequest(token, PACKAGE_SUBSCRIBE_URL, data);
}

export function doProfessionalPackage(token, data){
    return resourceRequest(token, PROFESSIONAL_PACKAGE_URL, data);
}

export function doFetchTransactionsList(token, data){
    return resourceRequest(token, TRANSACTION_LIST_URL, data);
}