import { call, put, takeLatest } from 'redux-saga/effects';
import { FINGERSCAN } from "../../constant";
import errorMessage from '../../utils/error.json';
import { doChangePassword, doForgetPassword, doLogin, doRegistration, doToken, doVerifyEmail } from '../../service/auth';


function* loginTask(action) {
    try {
        yield put({ type: FINGERSCAN.LOGIN_LOADING });

        const { payload } = action;

        const res = yield call(doLogin, payload.data);

        if (res.status == 200) {
            yield put({
                type: FINGERSCAN.LOGIN_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.statusCode] : res.message = errorMessage[1000];
            yield put({
                type: FINGERSCAN.LOGIN_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.statusCode] : errorMessage[4000];
        yield put({
            type: FINGERSCAN.LOGIN_FAILURE,
            payload: errMsg
        })
    }
}

function* tokenTask(action) {
    try {
        yield put({ type: FINGERSCAN.TOKEN_LOADING });

        const { payload } = action;

        const res = yield call(doToken, payload.data);

        if (res.status == 200) {
            yield put({
                type: FINGERSCAN.TOKEN_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.statusCode] : res.message = errorMessage[1000];
            yield put({
                type: FINGERSCAN.TOKEN_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.statusCode] : errorMessage[4000];
        yield put({
            type: FINGERSCAN.TOKEN_FAILURE,
            payload: errMsg
        })
    }
}

function* registrationTask(action) {
    try {
        yield put({ type: FINGERSCAN.REGISTRATION_LOADING });

        const { payload } = action;

        const res = yield call(doRegistration, payload.data);

        if (res.status == 200) {
            yield put({
                type: FINGERSCAN.REGISTRATION_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.statusCode] : res.message = errorMessage[1000];
            yield put({
                type: FINGERSCAN.REGISTRATION_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.statusCode] : errorMessage[4000];
        yield put({
            type: FINGERSCAN.REGISTRATION_FAILURE,
            payload: errMsg
        })
    }
}

function* verifyEmailTask(action) {
    try {
        yield put({ type: FINGERSCAN.VERIFY_EMAIL_LOADING });

        const { payload } = action;

        const res = yield call(doVerifyEmail, payload.data);

        if (res.status == 200) {
            yield put({
                type: FINGERSCAN.VERIFY_EMAIL_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.statusCode] : res.message = errorMessage[1000];
            yield put({
                type: FINGERSCAN.VERIFY_EMAIL_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.statusCode] : errorMessage[4000];
        yield put({
            type: FINGERSCAN.VERIFY_EMAIL_FAILURE,
            payload: errMsg
        })
    }
}

function* forgetPasswordTask(action) {
    try {
        yield put({ type: FINGERSCAN.FORGET_PASSWORD_LOADING });

        const { payload } = action;

        const res = yield call(doForgetPassword, payload.data);

        if (res.status == 200) {
            yield put({
                type: FINGERSCAN.FORGET_PASSWORD_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.statusCode] : res.message = errorMessage[1000];
            yield put({
                type: FINGERSCAN.FORGET_PASSWORD_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.statusCode] : errorMessage[4000];
        yield put({
            type: FINGERSCAN.FORGET_PASSWORD_FAILURE,
            payload: errMsg
        })
    }
}

function* changePasswordTask(action) {
    try {
        yield put({ type: FINGERSCAN.CHANGE_PASSWORD_LOADING });

        const { payload } = action;

        const res = yield call(doChangePassword, payload.token, payload.data);

        if (res.status == 200) {
            yield put({
                type: FINGERSCAN.CHANGE_PASSWORD_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.statusCode] : res.message = errorMessage[1000];
            yield put({
                type: FINGERSCAN.CHANGE_PASSWORD_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.statusCode] : errorMessage[4000];
        yield put({
            type: FINGERSCAN.CHANGE_PASSWORD_FAILURE,
            payload: errMsg
        })
    }
}

function* authSaga() {
    yield takeLatest(FINGERSCAN.LOGIN_REQUEST, loginTask);
    yield takeLatest(FINGERSCAN.TOKEN_REQUEST, tokenTask);
    yield takeLatest(FINGERSCAN.REGISTRATION_REQUEST, registrationTask);
    yield takeLatest(FINGERSCAN.FORGET_PASSWORD_REQUEST, forgetPasswordTask);
    yield takeLatest(FINGERSCAN.CHANGE_PASSWORD_REQUEST, changePasswordTask);
    yield takeLatest(FINGERSCAN.VERIFY_EMAIL_REQUEST, verifyEmailTask);
}

export default authSaga;