import React, { useEffect } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";

import { EmailVerificationPage, ForgetPasswordPage, LoginPage } from "../page/Login";

import {
  ERROR_404_PAGE,
  NAVIGATE_TO_PACKAGEPAGE,
  NAVIGATE_TO_TRANSACTIONHISTORYPAGE,
  NAVIGATE_TO_REGISTRATIONPAGE,
  NAVIGATE_TO_SUPPORTPAGE,
  NAVIGATE_TO_TOKENPAGE,
  NAVIGATE_TO_APIACCESSPAGE,
  NAVIGATE_TO_DASHBOARD,
  NAVIGATE_TO_INFOPAGE,
  NAVIGATE_TO_LOGINPAGE,
  NAVIGATE_TO_LOGSPAGE,
  NAVIGATE_TO_PROFILEPAGE,
  NAVIGATE_TO_VIEWREFERENCEPAGE,
  NAVIGATE_TO_DEVELOPERGUIDEPAGE,
  NAVIGATE_TO_PAYMENTREQUESTPAGE,
  NAVIGATE_TO_EMAILVERIFICATIONPAGE,
  NAVIGATE_TO_FORGETPASSWORDPAGE,
} from "../route/types";
import { STATUS } from "../constant";
import Error404Page from "../page/ErrorsPages/404Error";
import { Main } from "../components";
import { TransactionHistoryPage, PackagePage, TokenCreationPage, RegistrationPage, ApiAccessPage, Dashboard, InfoPage, LogsPage, ProfilePage,SupportPage, ViewReferencePage, DeveloperGuidePage, PaymentRequestPage } from "../page";

// FUNCTION TO DIRECT ONLY AUTH USER TO THEIR PAGES
const ProtectRoute = ({ status }) => {
  return status == STATUS.SUCCESS ? (
    <Outlet />
  ) : (
    <Navigate to={NAVIGATE_TO_LOGINPAGE} />
  );
};

// MAIN FUNC TO RENDER ROUTES AND PAGES
const RoutesContainer = ({ loginStatus }) => {
  const location = useLocation();

  return (
    <Routes key={location.pathname} location={location}>

      <Route path={NAVIGATE_TO_LOGINPAGE} element={<LoginPage />} />
      <Route path={NAVIGATE_TO_REGISTRATIONPAGE} element={<RegistrationPage/>}/>
      <Route path={NAVIGATE_TO_EMAILVERIFICATIONPAGE} element={<EmailVerificationPage/>}/>
      <Route path={NAVIGATE_TO_FORGETPASSWORDPAGE} element={<ForgetPasswordPage/>}/>


      <Route path="/" element={<ProtectRoute status={loginStatus} />}>
        <Route path='/' element={<Main />}>
          <Route index element={<Dashboard />} />
          <Route path={NAVIGATE_TO_PROFILEPAGE} element={<ProfilePage/>}/>

          <Route path={NAVIGATE_TO_TOKENPAGE} element={<TokenCreationPage/>}/>
          <Route path={NAVIGATE_TO_VIEWREFERENCEPAGE} element={<ViewReferencePage/>}/>

          <Route path={NAVIGATE_TO_TRANSACTIONHISTORYPAGE} element={<TransactionHistoryPage/>}/>
          <Route path={NAVIGATE_TO_PACKAGEPAGE} element={<PackagePage/>}/>
          <Route path={NAVIGATE_TO_PAYMENTREQUESTPAGE} element={<PaymentRequestPage/>}/>
          
          <Route path={NAVIGATE_TO_APIACCESSPAGE} element={<ApiAccessPage/>}/>
          <Route path={NAVIGATE_TO_LOGSPAGE} element={<LogsPage/>}/>
          <Route path={NAVIGATE_TO_DEVELOPERGUIDEPAGE} element={<DeveloperGuidePage/>}/>

          <Route path={NAVIGATE_TO_SUPPORTPAGE} element={<SupportPage />} />
          <Route path={NAVIGATE_TO_INFOPAGE} element={<InfoPage/>}/>
        </Route>
      </Route>

      <Route path={ERROR_404_PAGE} element={<Error404Page />} />
    </Routes>
  );
};

export default RoutesContainer;
