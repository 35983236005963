import { FINGERSCAN, STATUS } from "../../constant";

const INITIAL_STATE = {
    loginStatus: STATUS.DEFAULT,
    loginResult: null,
    loginErrorMessage: "",
    accessToken: null,

    tokenStatus: STATUS.DEFAULT,
    tokenResult: null,
    tokenErrorMessage: "",

    registrationStatus: STATUS.DEFAULT,
    registrationResult: null,
    registrationErrorMessage: "",

    forgetPasswordStatus: STATUS.DEFAULT,
    forgetPasswordResult: null,
    forgetPasswordErrorMessage: "",

    changePasswordStatus: STATUS.DEFAULT,
    changePasswordResult: null,
    changePasswordErrorMessage: "",

    verifyEmailStatus: STATUS.DEFAULT,
    verifyEmailResult: null,
    verifyEmailErrorMessage: ''
}

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        /* ------ LOGIN REDUCERS ----- */
        case FINGERSCAN.LOGIN_LOADING:
            return {
                ...state,
                loginStatus: STATUS.LOADING
            }
        case FINGERSCAN.LOGIN_SUCCESS:
            return {
                ...state,
                loginStatus: STATUS.SUCCESS,
                loginResult: payload,
                loginErrorMessage: "",
                accessToken: payload.message.authentication.accessToken
            }
        case FINGERSCAN.LOGIN_FAILURE:
            return {
                ...state,
                loginStatus: STATUS.ERROR,
                loginResult: null,
                loginErrorMessage: payload,
                accessToken: null,
            }
        case FINGERSCAN.LOGIN_RESET:
            return {
                ...state,
                loginStatus: STATUS.DEFAULT,
                loginResult: null,
                loginErrorMessage: "",
                accessToken: null,
            }

            /* ------ TOKEN REDUCERS ----- */
        case FINGERSCAN.TOKEN_LOADING:
            return {
                ...state,
                tokenStatus: STATUS.LOADING
            }
        case FINGERSCAN.TOKEN_SUCCESS:
            return {
                ...state,
                tokenStatus: STATUS.SUCCESS,
                tokenResult: payload,
                accessToken: payload.accessToken,
                tokenErrorMessage: ""
            }
        case FINGERSCAN.TOKEN_FAILURE:
            return {
                ...state,
                tokenStatus: STATUS.ERROR,
                tokenResult: null,
                tokenErrorMessage: payload
            }
        case FINGERSCAN.TOKEN_RESET:
            return {
                ...state,
                tokenStatus: STATUS.DEFAULT,
                tokenResult: null,
                tokenErrorMessage: ""
            }

        /* ------ REGISTRATION REDUCERS ------ */
        case FINGERSCAN.REGISTRATION_LOADING:
            return {
                ...state,
                registrationStatus: STATUS.LOADING
            }
        case FINGERSCAN.REGISTRATION_SUCCESS:
            return {
                ...state,
                registrationStatus: STATUS.SUCCESS,
                registrationResult: payload,
                registrationErrorMessage: ""
            }
        case FINGERSCAN.REGISTRATION_FAILURE:
            return {
                ...state,
                registrationStatus: STATUS.ERROR,
                registrationResult: null,
                registrationErrorMessage: payload
            }
        case FINGERSCAN.REGISTRATION_RESET:
            return {
                ...state,
                registrationStatus: STATUS.DEFAULT,
                registrationResult: null,
                registrationErrorMessage: ""
            }

             /* ------ VERIFY_EMAIL REDUCERS ------ */
        case FINGERSCAN.VERIFY_EMAIL_LOADING:
            return {
                ...state,
                verifyEmailStatus: STATUS.LOADING
            }
        case FINGERSCAN.VERIFY_EMAIL_SUCCESS:
            return {
                ...state,
                verifyEmailStatus: STATUS.SUCCESS,
                verifyEmailResult: payload,
                verifyEmailErrorMessage: ""
            }
        case FINGERSCAN.VERIFY_EMAIL_FAILURE:
            return {
                ...state,
                verifyEmailStatus: STATUS.ERROR,
                verifyEmailResult: null,
                verifyEmailErrorMessage: payload
            }
        case FINGERSCAN.VERIFY_EMAIL_RESET:
            return {
                ...state,
                verifyEmailStatus: STATUS.DEFAULT,
                verifyEmailResult: null,
                verifyEmailErrorMessage: ""
            }

        /* ------ FORGET PASSWORD REDUCERS ------ */
        case FINGERSCAN.FORGET_PASSWORD_LOADING:
            return {
                ...state,
                forgetPasswordStatus: STATUS.LOADING
            }
        case FINGERSCAN.FORGET_PASSWORD_SUCCESS:
            return {
                ...state,
                forgetPasswordStatus: STATUS.SUCCESS,
                forgetPasswordResult: payload,
                forgetPasswordErrorMessage: ""
            }
        case FINGERSCAN.FORGET_PASSWORD_FAILURE:
            return {
                ...state,
                forgetPasswordStatus: STATUS.ERROR,
                forgetPasswordResult: null,
                forgetPasswordErrorMessage: payload
            }
        case FINGERSCAN.FORGET_PASSWORD_RESET:
            return {
                ...state,
                forgetPasswordStatus: STATUS.DEFAULT,
                forgetPasswordResult: null,
                forgetPasswordErrorMessage: ""
            }

        /* ------ CHANGE PASSWORD REDUCERS ------ */
        case FINGERSCAN.CHANGE_PASSWORD_LOADING:
            return {
                ...state,
                changePasswordStatus: STATUS.LOADING
            }
        case FINGERSCAN.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordStatus: STATUS.SUCCESS,
                changePasswordResult: payload,
                changePasswordErrorMessage: ""
            }
        case FINGERSCAN.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changePasswordStatus: STATUS.ERROR,
                changePasswordResult: null,
                changePasswordErrorMessage: payload
            }
        case FINGERSCAN.CHANGE_PASSWORD_RESET:
            return {
                ...state,
                changePasswordStatus: STATUS.DEFAULT,
                changePasswordResult: null,
                changePasswordErrorMessage: ""
            }

        default:
            return state;
    }
}