import { FINGERSCAN, STATUS } from "../../constant";

const INITIAL_STATE = {
    apiStatus: STATUS.DEFAULT,
    apiResult: null,
    apiErrorMessage: "",

    viewApiStatus: STATUS.DEFAULT,
    viewApiResult: null,
    viewApiErrorMessage: "",

    logsStatus: STATUS.DEFAULT,
    logsResult: null,
    logsErrorMessage: "",
}

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        /* ------ API REDUCERS ----- */
        case FINGERSCAN.API_LOADING:
            return {
                ...state,
                apiStatus: STATUS.LOADING
            }
        case FINGERSCAN.API_SUCCESS:
            return {
                ...state,
                apiStatus: STATUS.SUCCESS,
                apiResult: payload,
                apiErrorMessage: ""
            }
        case FINGERSCAN.API_FAILURE:
            return {
                ...state,
                apiStatus: STATUS.ERROR,
                apiResult: null,
                apiErrorMessage: payload
            }
        case FINGERSCAN.API_RESET:
            return {
                ...state,
                apiStatus: STATUS.DEFAULT,
                apiResult: null,
                apiErrorMessage: ""
            }

        /* ------ VIEW API REDUCERS ----- */
        case FINGERSCAN.VIEW_API_LOADING:
            return {
                ...state,
                viewApiStatus: STATUS.LOADING
            }
        case FINGERSCAN.VIEW_API_SUCCESS:
            return {
                ...state,
                viewApiStatus: STATUS.SUCCESS,
                viewApiResult: payload,
                viewApiErrorMessage: ""
            }
        case FINGERSCAN.VIEW_API_FAILURE:
            return {
                ...state,
                viewApiStatus: STATUS.ERROR,
                viewApiResult: null,
                viewApiErrorMessage: payload
            }
        case FINGERSCAN.VIEW_API_RESET:
            return {
                ...state,
                viewApiStatus: STATUS.DEFAULT,
                viewApiResult: null,
                viewApiErrorMessage: ""
            }

        /* ------ LOGS REDUCERS ------ */
        case FINGERSCAN.LOGS_LOADING:
            return {
                ...state,
                logsStatus: STATUS.LOADING
            }
        case FINGERSCAN.LOGS_SUCCESS:
            return {
                ...state,
                logsStatus: STATUS.SUCCESS,
                logsResult: payload,
                logsErrorMessage: ""
            }
        case FINGERSCAN.LOGS_FAILURE:
            return {
                ...state,
                logsStatus: STATUS.ERROR,
                logsResult: null,
                logsErrorMessage: payload
            }
        case FINGERSCAN.LOGS_RESET:
            return {
                ...state,
                logsStatus: STATUS.DEFAULT,
                logsResult: null,
                logsErrorMessage: ""
            }


        default:
            return state;
    }
}