import { CHANGE_PASSWORD_URL, FORGET_PASSWORD_URL, LOGIN_URL, REGISTRATION_URL, RESEND_EMAIL_URL, TOKEN_URL, VERIFY_EMAIL_URL } from '../../constant';
import { guestRequest, resourceRequest } from '../calls'

export function doLogin(data) {
    return guestRequest(LOGIN_URL, data)
}

export function doToken(data){
    return guestRequest(TOKEN_URL, data);
}

export function doRegistration(data) {
    return guestRequest(REGISTRATION_URL, data);
}

export function doVerifyEmail(data){
    return guestRequest(VERIFY_EMAIL_URL, data);
}

export function doForgetPassword(data) {
    return guestRequest(FORGET_PASSWORD_URL, data);
}

export function doChangePassword(token, data) {
    return resourceRequest(token,CHANGE_PASSWORD_URL, data);
}

export function doResendEmailOTP (data){
    return guestRequest(RESEND_EMAIL_URL, data);
}