import * as React from 'react';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton, { IconButtonProps } from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import { NAVIGATE_TO_DASHBOARD, NAVIGATE_TO_EMAILVERIFICATIONPAGE, NAVIGATE_TO_FORGETPASSWORDPAGE, NAVIGATE_TO_REGISTRATIONPAGE } from '../../../route/types';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Avatar, Sheet } from '@mui/joy';
import image from '../../../constant/image';
import { theme } from '../../../utils';
import { EmailOutlined, KeyOutlined } from '@mui/icons-material';
import { STATUS } from '../../../constant';
import { loginRequest, loginReset, verifyEmailReset } from '../../../store/actions';
import { LanguageMenu, LoadingView } from '../../../components';
// import { getCurrentLang, translate } from '../../../i18n';
import { useTranslation } from "react-i18next";


function ColorSchemeToggle(props: IconButtonProps) {
    const { onClick, ...other } = props;
    const { mode, setMode } = useColorScheme();
    const [mounted, setMounted] = React.useState(false);

    React.useEffect(() => setMounted(true), []);


    return (
        <IconButton
            aria-label="toggle light/dark mode"
            size="sm"
            variant="outlined"
            disabled={!mounted}
            onClick={(event) => {
                setMode(mode === 'light' ? 'dark' : 'light');
                onClick?.(event);
            }}
            {...other}
        >
            {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
        </IconButton>
    );
}

const LoginPage = ({
    loginStatus,
    loginResult,
    loginErrorMessage,

    verifyEmailStatus,
    verifyEmailResult,
    verifyEmailErrorMessage
}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { t } = useTranslation()
    let translate = t

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        if (loginStatus === STATUS.SUCCESS) {
            navigate(NAVIGATE_TO_DASHBOARD, { replace: true });
            // setOTPModal(true)
            // dispatch(loginReset())
        }
        else if (loginStatus === STATUS.ERROR) {


            if (loginErrorMessage == "Email not verified") {
                toast.error(translate("login.notVerified"))
                navigate(NAVIGATE_TO_EMAILVERIFICATIONPAGE, {
                    state: {
                        email: username
                    }
                })
            } else {
                toast.error(loginErrorMessage)
            }

            dispatch(loginReset());
        }
    }, [loginStatus, verifyEmailStatus])

    useEffect(() => {
        console.log("Refresh screen")
    }, [refresh])

    // ---- FUNCTION TO HANDLE LOGIN FORM SUBMITION -----
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            email: username,
            password: password
        };
        console.log(data)
        if (data.email != "" && data.password != "") {
            setUsername(data.email)
            dispatch(loginRequest(data))

        } else {
            toast.error(translate("login.emptyErr"));
        }
    }


    const check_loading = () => {
        if (loginStatus === STATUS.LOADING) {
            return true
        } else {
            return false
        }
    }
    return (
        <CssVarsProvider defaultMode="dark" disableTransitionOnChange theme={theme}>
            <CssBaseline />
            <GlobalStyles
                styles={{
                    ':root': {
                        '--Form-maxWidth': '800px',
                        '--Transition-duration': '0.4s', // set to `none` to disable transition
                    },
                }}
            />
            <LoadingView loading={check_loading()} />
            <Box
                sx={(theme) => ({
                    width: { xs: '100%', md: '50vw' },
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backdropFilter: 'blur(12px)',
                    backgroundColor: 'rgba(255 255 255 / 0.2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundColor: 'rgba(19 19 24 / 0.4)',
                    },
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        width: '100%',
                        px: 2,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 3,
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                            {/* <IconButton variant="outlined" color="primary" size="sm"> */}
                            <Avatar
                                src={image.Images.icon}
                                size='sm'
                                sx={{
                                    width: 35, height: 34, backgroundColor: 'white', borderRadius: 10, p: 0.3
                                }}
                            />
                            {/* </IconButton> */}
                            <Typography level="title-lg" sx={{ fontFamily: 'Roboto Mono Variable, monospace' }}>{t("intro.appName")}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <LanguageMenu change={() => setRefresh(!refresh)} />
                            <ColorSchemeToggle />
                        </Box>

                    </Box>
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .MuiFormLabel-asterisk`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <Stack gap={4} sx={{ mb: 2 }}>
                            <Stack gap={1}>
                                <Typography component="h1" level="h3">
                                    {translate("login.title")}
                                </Typography>
                                <Typography level="body-sm">
                                    {translate("login.switch")}{' '}
                                    <Link href={NAVIGATE_TO_REGISTRATIONPAGE} level="title-sm">
                                        {translate("login.signup")}!
                                    </Link>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Divider
                            sx={(theme) => ({
                                [theme.getColorSchemeSelector('light')]: {
                                    color: { xs: '#FFF', md: 'text.tertiary' },
                                },
                            })}
                        >
                            or
                        </Divider>

                        {/* <a href={`http://192.168.100.8:3000/verify-email?email=${encodeURIComponent('godlisten.kundi@tbridge.co.tz')}"`}>Verify Email</a> */}
                        <Stack gap={2} sx={{ mt: 2 }}>
                            <form
                                onSubmit={handleSubmit}
                            >
                                {/* email */}
                                <FormControl required>
                                    <FormLabel>{translate("login.email")}</FormLabel>
                                    <Input
                                        type="email"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        placeholder={translate('login.emailPlaceholder')}
                                        endDecorator={<EmailOutlined />} />
                                </FormControl>

                                {/* password */}
                                <FormControl required>
                                    <FormLabel>{translate('login.password')}</FormLabel>
                                    <Input
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder='*******'
                                        endDecorator={<KeyOutlined />} />
                                </FormControl>

                                <Stack gap={4} sx={{ mt: 2 }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {/* <Checkbox size="sm" label="Remember me" name="persistent" /> */}
                                        <Link level="title-sm" href={NAVIGATE_TO_FORGETPASSWORDPAGE}>
                                            {translate('login.forgot')}
                                        </Link>
                                    </Box>
                                    <Button type="submit" fullWidth>
                                        {translate('login.loginButton')}
                                    </Button>
                                </Stack>
                            </form>
                        </Stack>
                    </Box>
                    <Box component="footer" sx={{ py: 3 }}>
                        <Typography level="body-xs" textAlign="center">
                            {translate("intro.footer")} {new Date().getFullYear()}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={(theme) => ({
                    height: '100%',
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: { xs: 0, md: '50vw' },
                    transition:
                        'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    backgroundColor: 'background.level1',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage:
                        'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundImage:
                            'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)',
                    },
                })}
            >
                <Sheet
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 5,
                        top: '25%',
                        backgroundColor: 'transparent',
                        backdropFilter: 'blur(12px)',
                        gap: 1
                    }}>
                    <Typography level='h2' sx={{ color: 'white' }}>{t("intro.welcome")}</Typography>
                    <Typography level='body-sm' sx={{ color: 'white', textAlign: 'center' }}>{t("intro.welcomeWords")}</Typography>
                </Sheet>
            </Box>

        </CssVarsProvider>
    );
}

const mapStateToProps = ({ auth }) => {
    const {
        loginStatus: loginStatus,
        loginResult: loginResult,
        loginErrorMessage: loginErrorMessage,

        verifyEmailStatus,
        verifyEmailResult,
        verifyEmailErrorMessage
    } = auth

    return {
        loginResult,
        loginErrorMessage,
        loginStatus,

        verifyEmailStatus,
        verifyEmailResult,
        verifyEmailErrorMessage
    }
}
export default connect(mapStateToProps, {})(LoginPage)