import { combineReducers } from "redux";

import authReducer from "./auth/reducers";
import dashboardReducer from "./dashboard/reducers";
import referenceReducer from "./reference/reducers";
import integrationReducer from "./integrations/reducers";
import paymentsReducer from "./payments/reducers";

import userTypeReducer from './userType/reducer'
import { ACTION_RESET_APP_STATE } from '../constant';

const appReducers = combineReducers({
    auth: authReducer,
    dashboard: dashboardReducer,
    reference: referenceReducer,
    integration: integrationReducer,
    payments: paymentsReducer,

    user: userTypeReducer
});

const rootReducer = (state, action) => {
    //added to prevent stacking on loading possibility
    if(action.type === ACTION_RESET_APP_STATE) {
        return appReducers(undefined, action);
    }
    return appReducers(state, action);
}

export default appReducers;