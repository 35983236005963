import React, { Fragment, useEffect, useState } from "react";
import { Typography, Box, Card, CardContent, Stack, FormControl, Input, Avatar, Divider, Textarea, FormLabel, Button, Select, Option, Sheet } from "@mui/joy";
import { toast } from 'react-toastify';
import { Main, PageTitle } from "../../../components";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { AssignmentOutlined, EditNoteOutlined, RedeemOutlined } from "@mui/icons-material";
import locations from '../../../assets/locations.json'

const PaymentRequestPage = ({
    accessToken,
    loginResult
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const sectorList = ['Finance', 'Agriculture', 'Education', 'Health Care', 'Information Technology (IT)',
        'Retail', 'Manufacturing', 'Real Estate', 'Energy', 'Transportation and Logistics', 'Tourism', 'Telecommunication', 'Others'];

    const seasonList = ['Month', 'Year']

    const { package_data, have_company } = state;

    const [company, setCompany] = useState('');
    const [name, setName] = useState(loginResult.message.name || "");
    const [email, setEmail] = useState(loginResult.message.email || "");
    const [phone, setPhone] = useState('');
    const [sector, setSector] = useState('');
    const [comment, setComment] = useState('');
    const [aboutUs, setAbout] = useState('');
    const [packages, setPackage] = useState('');
    const [duration, setDuration] = useState(1);
    const [season, setSeason] = useState('Month');

    // ... location handle change .... //
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [countryList, setCountryList] = useState([])
    const [cityList, setCityList] = useState([])
    const [countrySN, setCountrySN] = useState('');

    useEffect(() => {
        let _country = [];
        locations.map(val => {
            _country.push(val.name);
        });
        setCountryList(_country);
    }, []);

    useEffect(() => {
        //first clear selected district
        setCity('')
        locations.find((location) => {
            //return location.name = region
            let _cityList = []
            if (location.name == country) {
                location.cities.map(val => {
                    _cityList.push(val.name);
                })
                setCityList(_cityList);
                return;
            }
        });
    }, [country])

    // ... handle country selection ... //
    const handleCountryChange = (event, newValue) => {
        setCountry(newValue)
        if (newValue) {
            let _country = newValue.split(" ").slice(1).join(" ");
            setCountrySN(_country)
        }
    }

    // .... handle city selection ... //
    const handleCityChange = (evant, newValue) => {
        setCity(newValue)
    }

    // .... handle sector selection .... //
    const handleSelectSector = (event, newValue) => {
        setSector(newValue)
    }

    // ... handle duration selection ... //
    const handleDurationChange = (event, newValue) => {
        setSeason(newValue)
    }

    const calculateAmount = () => {
        let amount = 0;
        let perMonth = parseFloat(package_data.price.month.replace("$", " "));
        let perYear = parseFloat(package_data.price.year.replace("$", " "));

        if (season === 'Year') {
            amount = perYear * duration;
        }
        else if (season === 'Month') {
            amount = perMonth * duration;
        }

        return amount
    }

    return (
        <Box>
            <PageTitle title={t("payment.title")} />

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Card
                    component='form'
                    onSubmit={null}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 0,
                        width: { xs: 'auto', md: '80%' },
                        maxWidth: 1000,
                        // justifyContent: 'center',
                        // alignItems: 'center',
                        // borderColor: 'black'
                    }}>
                    <Divider sx={{ '--Divider-childPosition': '0%', gap: 1 }}>
                        <Avatar size="md" sx={{ backgroundColor: 'text.primary' }}>
                            <AssignmentOutlined sx={{ color: 'background.body' }} />
                        </Avatar>
                        <Typography level="title-md">{t("payment.subTitle")}</Typography>
                    </Divider>
                    <Typography level="body-sm" sx={{ px: 2 }}>{t("payment.desc")}</Typography>

                    <Box
                        gap={2} mt={2}
                        sx={{
                            // width: '100%',
                            // maxWidth: 500,
                            display: 'flex',
                            px: 2,
                            flexDirection: 'column',

                        }}>
                        <Stack>
                            {/* company name */}
                            <FormControl required={have_company} sx={{ flex: 1 }}>
                                <FormLabel>{t("payment.company")}</FormLabel>
                                <Input
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    placeholder={t("payment.companyPlaceholder")}
                                    type="text"
                                />
                            </FormControl>
                        </Stack>

                        <Stack>
                            {/* user name */}
                            <FormControl required={have_company} sx={{ flex: 1 }}>
                                <FormLabel>{t("payment.name")}</FormLabel>
                                <Input
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder={t("payment.namePlaceholder")}
                                    type="text"
                                />
                            </FormControl>
                        </Stack>

                        <Stack direction={{ xs: 'column', md: 'row' }} gap={2} flex={1}>
                            {/* email */}
                            <FormControl required={have_company} sx={{ flex: 1 }}>
                                <FormLabel>{t("payment.email")}</FormLabel>
                                <Input
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder={t("payment.emailPlaceholder")}
                                    type="text"
                                />
                            </FormControl>

                            {/* phone */}
                            <FormControl required={have_company} sx={{ flex: 1 }}>
                                <FormLabel>{t("payment.mobile")}</FormLabel>
                                <Input
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder={t("payment.mobilePlaceholder")}
                                    type="text"
                                />
                            </FormControl>
                        </Stack>

                        <Stack direction={{ xs: 'column', md: 'row' }} gap={2} flex={1}>
                            {/* country */}
                            <FormControl required={have_company} sx={{ flex: 1 }}>
                                <FormLabel>{t("payment.country")}</FormLabel>
                                <Select value={country} placeholder={t("payment.countryPlaceholder")} onChange={handleCountryChange}>
                                    {countryList.map((item, index) => (
                                        <Option key={index} value={item}>{item}</Option>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* city */}
                            {country &&
                                <FormControl required={have_company} sx={{ flex: 1 }}>
                                    <FormLabel>{t("payment.city")}</FormLabel>
                                    <Select value={city} placeholder={t("payment.cityPlaceholder")} onChange={handleCityChange}>
                                        {cityList.map((item, index) => (
                                            <Option key={index} value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </FormControl>}
                        </Stack>

                        <Stack>
                            {/* sector */}
                            <FormControl required={have_company} sx={{ flex: 1 }}>
                                <FormLabel>{t("payment.sector")}</FormLabel>
                                <Select placeholder={t("payment.sectorPlaceholder")} value={sector} onChange={handleSelectSector}>
                                    {sectorList.map((item, index) => (
                                        <Option key={index} value={item}>{item}</Option>
                                    ))}

                                </Select>
                            </FormControl>
                        </Stack>

                        <Divider />

                        <Stack flex={1}>
                            {/* comments */}
                            <FormControl sx={{ flex: 1 }}>
                                <FormLabel>{t("payment.comments")}</FormLabel>
                                <Textarea
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    placeholder={t("payment.commentsPlaceholder")}
                                    minRows={5}
                                />
                            </FormControl>
                        </Stack>

                        <Stack flex={1}>
                            {/* About us */}
                            <FormControl sx={{ flex: 1 }}>
                                <FormLabel>{t("payment.hearUs")}</FormLabel>
                                <Input
                                    value={aboutUs}
                                    onChange={(e) => setAbout(e.target.value)}
                                // placeholder="Write your comments"
                                />
                            </FormControl>
                        </Stack>

                        <Divider />

                        {/* Package duration selection */}
                        <Typography level="title-sm">{t("payment.package")} {package_data.name} | {package_data.price.month} {t("payment.perMonth")}</Typography>
                        <Stack direction={{ xs: 'column', md: 'row' }} gap={2} flex={1}>
                            {/* duration */}
                            <FormControl required={have_company} sx={{ flex: 1 }}>
                                <FormLabel>{t("payment.quantity")}</FormLabel>
                                <Input
                                    type="number"
                                    value={duration}
                                    onChange={(e) => setDuration(e.target.valueAsNumber)}
                                    placeholder="Enter how many month/ year"
                                />
                            </FormControl>

                            {/* season */}
                            <FormControl required={have_company} sx={{ flex: 1 }}>
                                <FormLabel>{t("payment.duration")}</FormLabel>
                                <Select value={season} placeholder="Select duration" onChange={handleDurationChange}>
                                    {seasonList.map((item, index) => (
                                        <Option key={index} value={item}>{item}</Option>
                                    ))}
                                </Select>
                            </FormControl>

                            <Sheet variant="outlined" sx={{ flex: 1, boxShadow: 'sm', p: 0.5, backgroundColor: 'background.level1' }}>
                                <Typography level="title-sm" sx={{ textAlign: 'center' }}>{t("payment.calculate")}</Typography>
                                <Typography sx={{ textAlign: 'center' }}>${calculateAmount()} {{"Month": t("payment.perMonth"), "Year": t("payment.perYear")}[season]}</Typography>
                            </Sheet>
                        </Stack>

                        {/* submit form button */}
                        <Button
                            color="success"
                            type="submit"
                            sx={{
                                alignSelf: 'flex-start',
                                borderRadius: 4
                            }}>
                            {t("payment.submit")}
                        </Button>
                    </Box>
                </Card>
            </Box>


        </Box>





    )
}

const mapStateToProps = ({ auth, payments }) => {
    const {
        accessToken,
        loginResult
    } = auth

    return {
        accessToken,
        loginResult
    }
}

export default connect(mapStateToProps, {})(PaymentRequestPage)