export default {
    Images: {
        icon: require("../assets/image/icon.png"),
        logo: require("../assets/image/logo.png"),
        logoWhite: require("../assets/image/logo.png"),
        userIcon: require("../assets/image/userIcon.png"),
        userIcon2: require("../assets/image/userIcon2.jpg"),
        backgroung: require("../assets/image/background.png"),
        backgroung2: require("../assets/image/background2.png"),
        tzFlag: require("../assets/image/tanzania.png"),
        ukFlag: require("../assets/image/uk.png"),
        playstore: require("../assets/image/playstore.png"),
        webportal: require("../assets/image/webportal.png"),
    }
}