import { fork } from "@redux-saga/core/effects";

import authSaga from "./auth/sagas";
import dashboardSaga from "./dashboard/sagas";
import referenceSaga from "./reference/sagas";
import integrationSaga from "./integrations/sagas";
import paymentsSaga from "./payments/sagas";

export default function* root() {
    yield fork(authSaga);
    yield fork(dashboardSaga);
    yield fork(referenceSaga);
    yield fork(integrationSaga);
    yield fork(paymentsSaga);
}