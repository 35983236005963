import * as React from 'react';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton, { IconButtonProps } from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import { NAVIGATE_TO_DASHBOARD, NAVIGATE_TO_EMAILVERIFICATIONPAGE, NAVIGATE_TO_LOGINPAGE, NAVIGATE_TO_REGISTRATIONPAGE } from '../../../route/types';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Avatar, FormHelperText, LinearProgress, Select, Sheet, Option } from '@mui/joy';
import image from '../../../constant/image';
import { theme } from '../../../utils';
import { EmailOutlined, FlagOutlined, InfoOutlined, Key, LocationCityOutlined, PersonOutline, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { registrationRequest, registrationReset } from '../../../store/actions';
import { STATUS } from '../../../constant';
import { LoadingView } from '../../../components';
import { useTranslation } from 'react-i18next';
import locations from '../../../assets/locations.json'

interface FormElements extends HTMLFormControlsCollection {
    email: HTMLInputElement;
    // password: HTMLInputElement;
    first_name: HTMLInputElement;
    second_name: HTMLInputElement;
    country: HTMLInputElement;
    city: HTMLInputElement;
    zip_code: HTMLInputElement;
    //   persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

function ColorSchemeToggle(props: IconButtonProps) {
    const { onClick, ...other } = props;
    const { mode, setMode } = useColorScheme();
    const [mounted, setMounted] = React.useState(false);

    React.useEffect(() => setMounted(true), []);


    return (
        <IconButton
            aria-label="toggle light/dark mode"
            size="sm"
            variant="outlined"
            disabled={!mounted}
            onClick={(event) => {
                setMode(mode === 'light' ? 'dark' : 'light');
                onClick?.(event);
            }}
            {...other}
        >
            {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
        </IconButton>
    );
}

const RegistrationPage = ({
    registrationStatus,
    registrationResult,
    registrationErrorMessage
}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [OTPModal, setOTPModal] = useState(false);
    const [loadOTP, setLoadOTP] = useState(false);
    const [OTP, setOTP] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('');
    const [pass_visible, setPassVisible] = useState(false)

    useEffect(() => {
        if (registrationStatus === STATUS.SUCCESS) {
            toast.success(t("registration.success"))
            navigate(NAVIGATE_TO_EMAILVERIFICATIONPAGE, {
                state: {
                    email: username
                }
            });
            dispatch(registrationReset());
        }
        else if (registrationStatus === STATUS.ERROR) {
            toast.error(registrationErrorMessage);
            dispatch(registrationReset());
        }
    }, [registrationStatus])

    // ... location handle change .... //
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [countryList, setCountryList] = useState([])
    const [cityList, setCityList] = useState([])
    const [countrySN, setCountrySN] = useState('');

    useEffect(() => {
        let _country = [];
        locations.map(val => {
            _country.push(val.name);
        });
        setCountryList(_country);
    }, []);

    useEffect(() => {
        //first clear selected district
        setCity('')
        locations.find((location) => {
            //return location.name = region
            let _cityList = []
            if (location.name == country) {
                location.cities.map(val => {
                    _cityList.push(val.name);
                })
                setCityList(_cityList);
                return;
            }
        });
    }, [country])

    // ... handle country selection ... //
    const handleCountryChange = (event, newValue) => {
        setCountry(newValue)
        if (newValue) {
            let _country = newValue.split(" ").slice(1).join(" ");
            setCountrySN(_country)
        }
    }

    // .... handle city selection ... //
    const handleCityChange = (evant, newValue) => {
        setCity(newValue)
    }

    // ------ FUNCTION TO HANDLE REGISTRATION SUBMITION --------
    const handleSubmit = (event) => {
        event.preventDefault();
        const formElements = event.currentTarget.elements;
        const data = {
            name: formElements.first_name.value + " " + formElements.second_name.value,
            email: formElements.email.value,
            country: countrySN,
            city: city,
            zip_code: formElements.zip_code.value,
            // password: formElements.password.value,
            password: password
        };
        console.log(data)
        if (data.name && data.email && data.country && data.city && data.zip_code) {
            // navigate(NAVIGATE_TO_DASHBOARD)
            setUsername(data.email)
            dispatch(registrationRequest(data))

        } else {
            toast.error("Please fill out all fields");
        }
    }

    const check_loading = () => {
        if (registrationStatus === STATUS.LOADING) {
            return true
        } else {
            return false
        }
    }

    return (
        <CssVarsProvider defaultMode="dark" disableTransitionOnChange theme={theme}>
            <CssBaseline />
            <GlobalStyles
                styles={{
                    ':root': {
                        '--Form-maxWidth': '800px',
                        '--Transition-duration': '0.4s', // set to `none` to disable transition
                    },
                }}
            />

            <LoadingView loading={check_loading()} />
            <Box
                sx={(theme) => ({
                    width: { xs: '100%', md: '50vw' },
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backdropFilter: 'blur(12px)',
                    backgroundColor: 'rgba(255 255 255 / 0.2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundColor: 'rgba(19 19 24 / 0.4)',
                    },
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        width: '100%',
                        px: 2,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 3,
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                            {/* <IconButton variant="outlined" color="primary" size="sm"> */}
                            <Avatar
                                src={image.Images.icon}
                                size='sm'
                                sx={{
                                    width: 35, height: 34, backgroundColor: 'white', borderRadius: 10, p: 0.3
                                }}
                            />
                            {/* </IconButton> */}
                            <Typography level="title-lg" sx={{ fontFamily: 'Roboto Mono Variable, monospace' }}>{t("intro.appName")}</Typography>
                        </Box>
                        <ColorSchemeToggle />
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .MuiFormLabel-asterisk`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <Stack gap={4} sx={{ mb: 2 }}>
                            <Stack gap={1}>
                                <Typography component="h1" level="h3">
                                    {t("registration.title")}
                                </Typography>
                                <Typography level="body-sm">
                                    {t("registration.switch")}{' '}
                                    <Link href={NAVIGATE_TO_LOGINPAGE} level="title-sm">
                                        {t("registration.signin")}!
                                    </Link>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Divider
                            sx={(theme) => ({
                                [theme.getColorSchemeSelector('light')]: {
                                    color: { xs: '#FFF', md: 'text.tertiary' },
                                },
                            })}
                        >
                            or
                        </Divider>
                        <Stack gap={2} sx={{ mt: 2 }}>
                            <form onSubmit={handleSubmit}>

                                {/* first name */}
                                <FormControl required>
                                    <FormLabel>{t("registration.fistname")}</FormLabel>
                                    <Input
                                        type="text"
                                        name="first_name"
                                        placeholder={t("registration.firstnamePlaceholder")}
                                        endDecorator={<PersonOutline />} />
                                </FormControl>

                                {/* second name */}
                                <FormControl required>
                                    <FormLabel>{t("registration.surname")}</FormLabel>
                                    <Input
                                        type="text"
                                        name="second_name"
                                        placeholder={t("registration.surnamePlaceholder")}
                                        endDecorator={<PersonOutline />} />
                                </FormControl>

                                {/* email */}
                                <FormControl required>
                                    <FormLabel>{t("registration.email")}</FormLabel>
                                    <Input
                                        type="email"
                                        name="email"
                                        placeholder={t("registration.emailPlaceholder")}
                                        endDecorator={<EmailOutlined />} />
                                </FormControl>

                                {/* password */}
                                <FormControl required sx={{ '--hue': Math.min(password.length * 10, 120), }}>
                                    <FormLabel>{t("registration.password")}</FormLabel>
                                    <Input
                                        type={pass_visible ? "text" : "password"}
                                        placeholder="********"
                                        value={password}
                                        onChange={(event) => setPassword(event.target.value)}
                                        endDecorator={
                                            <IconButton onClick={() => setPassVisible(!pass_visible)}>
                                                {pass_visible ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                            </IconButton>
                                        }
                                    />
                                    {password.length > 0 &&
                                        <>
                                            <LinearProgress
                                                determinate
                                                size="sm"
                                                value={Math.min((password.length * 100) / 8, 100)}
                                                sx={{
                                                    bgcolor: 'background.level3',
                                                    color: 'hsl(var(--hue) 80% 40%)',
                                                    mt: 0.5
                                                }}
                                            />
                                            <Typography
                                                level="body-xs"
                                                sx={{ alignSelf: 'flex-end', color: 'hsl(var(--hue) 80% 30%)' }}
                                            >
                                                {password.length < 3 && t("registration.passwordStatus.veryWeak")}
                                                {password.length >= 3 && password.length < 6 && t("registration.passwordStatus.weak")}
                                                {password.length >= 6 && password.length < 8 && t("registration.passwordStatus.strong")}
                                                {password.length >= 8 && t("registration.passwordStatus.veryStrong")}
                                            </Typography>
                                        </>}
                                </FormControl>

                                {/* country */}
                                <FormControl required>
                                    <FormLabel>{t("registration.country")}</FormLabel>
                                    <Select value={country} placeholder={t("registration.countryPlaceholder")} onChange={handleCountryChange}>
                                        {countryList.map((item, index) => (
                                            <Option key={index} value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </FormControl>

                                {/* city */}
                                {country &&
                                    <FormControl required>
                                        <FormLabel>{t("registration.city")}</FormLabel>
                                        <Select value={city} placeholder={t("registration.cityPlaceholder")} onChange={handleCityChange}>
                                            {cityList.map((item, index) => (
                                                <Option key={index} value={item}>{item}</Option>
                                            ))}
                                        </Select>
                                    </FormControl>}

                                {/* zipcode */}
                                <FormControl required>
                                    <FormLabel>{t("registration.zipcode")}</FormLabel>
                                    <Input
                                        type="number"
                                        name="zip_code"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value.length > 5) {
                                                e.target.value = value.slice(0, 5); // Limit to 5 digits
                                            }
                                        }}
                                        placeholder={t("registration.zipcodePlaceholder")}
                                        endDecorator={<LocationCityOutlined />} />
                                </FormControl>

                                <Stack gap={4} sx={{ mt: 2 }}>
                                    <Button type="submit" fullWidth>
                                        {t("registration.button")}
                                    </Button>
                                </Stack>
                            </form>
                        </Stack>
                    </Box>
                    <Box component="footer" sx={{ py: 3 }}>
                        <Typography level="body-xs" textAlign="center">
                            © T-Bridge Technologies Company Limited {new Date().getFullYear()}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={(theme) => ({
                    height: '100%',
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: { xs: 0, md: '50vw' },
                    transition:
                        'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    backgroundColor: 'background.level1',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage:
                        'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundImage:
                            'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)',
                    },
                })}
            >
                <Sheet
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 5,
                        top: '25%',
                        backgroundColor: 'transparent',
                        backdropFilter: 'blur(12px)',
                        gap: 1
                    }}>
                    <Typography level='h2' sx={{ color: 'white' }}>{t("intro.welcome")}</Typography>
                    <Typography level='body-sm' sx={{ color: 'white', textAlign: 'center' }}>{t("intro.welcomeWords")}</Typography>
                </Sheet>
            </Box>
        </CssVarsProvider>
    );
}

const mapStateToProps = ({ auth }) => {
    const {
        registrationStatus: registrationStatus,
        registrationResult: registrationResult,
        registrationErrorMessage: registrationErrorMessage
    } = auth

    return {
        registrationStatus,
        registrationResult,
        registrationErrorMessage
    }
}

export default connect(mapStateToProps, {})(RegistrationPage)