import { call, put, takeLatest } from 'redux-saga/effects';
import { FINGERSCAN } from "../../constant";
import errorMessage from '../../utils/error.json';
import { doFetchDashboard } from '../../service/dashboard';


function* dashboardTask(action) {
    try {
        yield put({ type: FINGERSCAN.DASHBOARD_LOADING });

        const { payload } = action;

        const res = yield call(doFetchDashboard,payload.token, payload.data);

        if (res.status == 200) {
            yield put({
                type: FINGERSCAN.DASHBOARD_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.statusCode] : res.message = errorMessage[1000];
            yield put({
                type: FINGERSCAN.DASHBOARD_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.statusCode] : errorMessage[4000];
        yield put({
            type: FINGERSCAN.DASHBOARD_FAILURE,
            payload: errMsg
        })
    }
}

function* dashboardSaga() {
    yield takeLatest(FINGERSCAN.DASHBOARD_REQUEST, dashboardTask);
}

export default dashboardSaga;