import { FINGERSCAN } from "../../constant";


/* ------- API ACTIONS ------ */
export function apiRequest(token, data) {
    return {
        type: FINGERSCAN.API_REQUEST,
        payload: {
            token,
            data
        }
    }

}

export function apiReset() {
    return {
        type: FINGERSCAN.API_RESET
    }
}

/* ------- VIEW API ACTIONS ------ */
export function viewApiRequest(token, data) {
    return {
        type: FINGERSCAN.VIEW_API_REQUEST,
        payload: {
            token,
            data
        }
    }

}

export function viewApiReset() {
    return {
        type: FINGERSCAN.VIEW_API_RESET
    }
}

/* ------ LOGSS ACTIONS ------ */
export function logsRequest(token, data) {
    return {
        type: FINGERSCAN.LOGS_REQUEST,
        payload: {
            token,
            data
        }
    }
}

export function logsReset() {
    return {
        type: FINGERSCAN.LOGS_RESET
    }
}

