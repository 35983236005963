import React, { Fragment, useEffect, useState } from "react";
import { Typography, Box, Card, Avatar, Button, FormControl, FormLabel, Input, Divider, IconButton, Textarea, ListItemContent, } from "@mui/joy";
import { toast } from 'react-toastify';
import { Main, PageTitle } from "../../../components";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { STATUS } from "../../../constant";
import { EmailRounded, LocationOnRounded, PhoneRounded, Public, QuestionAnswerRounded, QuestionMarkRounded, TapAndPlayRounded, VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import image from "../../../constant/image";
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails, {
    accordionDetailsClasses,
} from '@mui/joy/AccordionDetails';
import AccordionSummary, {
    accordionSummaryClasses,
} from '@mui/joy/AccordionSummary';


// .... FEEDBACKS COMPONENT ... //
const FeedbacksComponent = () => {
    const { t } = useTranslation();
    const [subject, setSubject] = useState('');
    const [comment, setComment] = useState('');


    return (
        <Box
            component="form"
            onSubmit={null}
            sx={{
                display: "flex",
                flexDirection: 'column',
                flex: 1,
                gap: 2
            }}>
            <Typography level="title-sm" sx={styles.title}>{t("support.feedbackTitle")}</Typography>

            <FormControl required sx={{ flex: 1 }}>
                <FormLabel sx={styles.label}>{t("support.subject")}</FormLabel>
                <Input
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    // placeholder="Enter your"
                    type="text"
                    sx={styles.input} />
            </FormControl>

            <Divider sx={{ backgroundColor: 'white' }} />

            <FormControl required sx={{ flex: 1 }}>
                <FormLabel sx={styles.label}>{t("support.comments")}</FormLabel>

                <Textarea
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    minRows={4}
                    placeholder={t("support.commentsPlaceholder")}
                    // type="text"
                    sx={styles.input} />
            </FormControl>

            <Button
                type="submit"
                color="success"
                sx={{ mt: 2 }}>
                {t("support.submit")}
            </Button>
        </Box>
    )
}

// ... FAQ COMPONENT ... //
const FAQComponent = () => {
    const { t } = useTranslation();

    const questions = [
        {
            title: t("support.FAQList.q1"),
            ans: t("support.FAQList.ans1")
        },
        {
            title: t("support.FAQList.q2"),
            ans: t("support.FAQList.ans2")
        },
        {
            title: t("support.FAQList.q3"),
            ans: t("support.FAQList.ans3")
        },
        {
            title: t("support.FAQList.q4"),
            ans: t("support.FAQList.ans4")
        },
        {
            title: t("support.FAQList.q5"),
            ans: t("support.FAQList.ans5")
        },
    ]


    return (
        <Box
            component="form"
            onSubmit={null}
            sx={{
                display: "flex",
                flexDirection: 'column',
                flex: 1,
                gap: 2
            }}>
            <Typography level="title-sm" sx={styles.title}>{t("support.FAQTitle")}</Typography>

            <AccordionGroup
                variant="plain"
                transition="0.2s"
                size="sm"
                sx={{
                    flex: 1,
                    borderRadius: 'md',
                    p: 1,
                    // backgroundColor: '#00000090',
                    [`& .${accordionDetailsClasses.content}.${accordionDetailsClasses.expanded}`]:
                    {
                        paddingBlock: '1rem',
                        backgroundColor: "background.level2",
                        borderRadius: 'sm',
                        px: 2

                    },
                    [`& .${accordionSummaryClasses.button}`]: {
                        paddingBlock: '1rem',
                        backgroundColor: 'background.surface',
                        "&:hover": {
                            backgroundColor: 'background.level1',
                        },
                        borderRadius: 'sm'
                    },
                }}
            >
                {questions.map((item, index) => (
                    <Accordion key={index}>
                        <AccordionSummary>
                            <Avatar size="sm" color="success">
                                <QuestionMarkRounded />
                            </Avatar>
                            <ListItemContent>
                                <Typography level="title-sm" sx={{ color: "text.primary" }}>{item.title}</Typography>
                            </ListItemContent>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography level="body-sm" sx={{ color: "text.secondary" }}>{item.ans}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </AccordionGroup>
        </Box>
    )
}

// ... MAIN SUPPORT PAGE .... //
const SupportPage = ({
    loginStatus,
    loginResult,
    loginErrorMessage,

    changePasswordStatus,
    changePasswordResult,
    changePasswordErrorMessage
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [tabOpt, setTabOpt] = useState("FAQ");

    return (
        <Box>
            <PageTitle title={t("support.title")} />
            <Box sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
            }}>
                <Card
                    variant="plain"
                    size="sm"
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: { xs: 'column-reverse', md: 'row' },
                        boxShadow: 'md',
                        p: 0,
                        borderRadius: 0,
                        background: 'background.surface',
                        // maxWidth: 900,
                    }}>

                    {/* ... Left part | Contacts ... */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: { xs: "auto", md: '40%' },
                            px: 2,
                            py: 4,
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            gap: 2
                        }}>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 2
                            }}>
                            <Avatar variant="outlined" sx={{ width: '50px', height: '50px', borderRadius: 10, p: 1, backgroundColor: "background.secondaryColor" }} src={image.Images.icon} />
                            <Box>
                                <Typography level="title-lg">{t("intro.appName")}</Typography>
                                <Typography level="body-xs">{t("intro.appDescription")}</Typography>
                            </Box>
                        </Box>

                        <Divider />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                p: 2
                            }}>
                            <Typography level="title-md">{t("support.ourContact")}</Typography>
                            <Typography component='a' href="mailto:support@fingerscan.net" target="_blank" startDecorator={<EmailRounded />} level="body-sm">support@fingerscan.net</Typography>
                            <Typography component='a' href="tel:+255752514638" target="_blank" startDecorator={<PhoneRounded />} level="body-sm">+255 752 514 638</Typography>
                            <Typography component='a' href="https://fingerscan.net" target="_blank" startDecorator={<Public />} level="body-sm">www.fingerscan.net</Typography>
                            <Typography component='a' href="https://maps.app.goo.gl/aDY5apXrQpvRTMb49" target="_blank" startDecorator={<LocationOnRounded />} level="body-sm">
                                Mikocheni B,
                                Coca Cola Road,
                                Auditax House 1ˢᵗ Floor,
                                Dar es salaam, Tanzania.
                            </Typography>
                        </Box>
                    </Box>

                    {/* ... Right part | Tab options */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: 'column',
                            width: { xs: "auto", md: '60%' },
                            boxShadow: '-10px 0px 20px rgba(0, 0, 0, 0.5)',
                            px: 2,
                            py: 4,
                            justifyContent: 'flex-start',
                            backgroundColor: '#0F1226',
                            gap: 2
                        }}>

                        {/* ... tab buttons ... */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1
                            }}>
                            {
                                ['FAQ', 'Feedbacks'].map((item, index) => (
                                    <Button
                                        key={index}
                                        size="sm"
                                        // variant={tabOpt === item ? "solid" : "outlined"}
                                        variant="plain"
                                        onClick={() => setTabOpt(item)}
                                        sx={{
                                            color: tabOpt === item ? "yellowgreen" : "gray",
                                            borderBottom: tabOpt === item ? 1.5 : 0,
                                            borderColor: tabOpt === item ? "yellowgreen" : "gray",
                                            borderRadius: 0,
                                            "&:hover": {
                                                backgroundColor: '#00000090'
                                            }
                                        }}
                                    >
                                        {{
                                            "FAQ": t("support.FAQ"),
                                            "Chat": t("support.chat"),
                                            "Feedbacks": t("support.feedback")
                                        }[item]}
                                    </Button>
                                ))
                            }
                        </Box>
                        {/* ... Render FAQ component */}
                        {tabOpt === "FAQ" && <FAQComponent />}

                        {/* ... Render Feedback component .... */}
                        {tabOpt === "Feedbacks" && <FeedbacksComponent />}
                    </Box>
                </Card>
            </Box>
        </Box>
    )
}

const styles = {
    input: {
        color: 'white',
        borderColor: 'white',
        backgroundColor: 'transparent',
        borderRadius: 4,
        '&::before': {
            display: 'none',
        },
        '&:focus-within': {
            borderColor: 'green',
            outline: '2px solid var(--Input-focusedHighlight)',
            outlineOffset: '2px',
            outlineColor: 'yellowgreen'
        },
    },
    label: {
        color: "whitesmoke"
    },
    title: {
        color: "white"
    },

}

const mapStateToProps = ({ auth }) => {
    const {
        loginStatus,
        loginResult,
        loginErrorMessage,

        changePasswordStatus,
        changePasswordResult,
        changePasswordErrorMessage,
    } = auth

    return {
        loginStatus,
        loginResult,
        loginErrorMessage,

        changePasswordStatus,
        changePasswordResult,
        changePasswordErrorMessage
    }


}

export default connect(mapStateToProps, {})(SupportPage)