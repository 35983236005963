import { API_URL, LOGS_URL, VIEW_API_URL } from "../../constant";
import { resourceRequest } from "../calls";

export function doAPI(token, data){
    return resourceRequest(token, API_URL, data);
}

export function doViewAPI(token, data){
    return resourceRequest(token, VIEW_API_URL, data);
}

export function doFetchLogs(token, data){
    return resourceRequest(token, LOGS_URL, data);
}