import React, { Fragment, useEffect, useState } from "react";
import { Typography, Box, Card } from "@mui/joy";
import { toast } from 'react-toastify';
import { Main, PageTitle } from "../../../components";
import { useTranslation } from "react-i18next";
import { InfoOutlined, WarningRounded } from "@mui/icons-material";

const TransactionHistoryPage = () => {

    const { t } = useTranslation()

    const [historyList, setHistoryList] = useState([]);

    return (
        <Box>
            <PageTitle title={t("transaction.title")} />

            <Typography
                level="body-md"
                variant="soft"
                color="danger"
                startDecorator={<InfoOutlined />}
                sx={{
                    // fontFamily: 'Roboto Mono Variable', 
                    px: 1,
                    py: 0.8,
                    mb: 2,
                    borderRadius: 'sm',
                    // backgroundColor: "lightblue" 
                }}
            >
                {t("transaction.desc")}
            </Typography>

            {historyList.length > 0 ?
                <Typography>Comming Soon</Typography>
                :
                <Card sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: '20%'
                }}>
                    <WarningRounded sx={{ fontSize: '50px', color: 'background.appcolor' }} />
                    <Typography level="title-md">{t("transaction.emptyData.header")}</Typography>
                    <Typography level="body-sm" textAlign={'center'}>{t("transaction.emptyData.desc")}</Typography>
                </Card>
            }


        </Box>





    )
}

export default TransactionHistoryPage