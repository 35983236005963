export const receiveJavaCode = `
        // This is Java code
        import React from 'react';
        import { Button } from '@mui/material';

        const MyButton = () => (
            <Button variant="contained" color="primary">
                Click Me
            </Button>
        );

        export default MyButton;
    `

export const receivePhpCode = `
        // This is Php code
        import React from 'react';
        import { Button } from '@mui/material';

        const MyButton = () => (
            <Button variant="contained" color="primary">
                Click Me
            </Button>
        );

        export default MyButton;
    `

export const receiveCPlusCode = `
        // This is C++ code
        import React from 'react';
        import { Button } from '@mui/material';

        const MyButton = () => (
            <Button variant="contained" color="primary">
                Click Me
            </Button>
        );

        export default MyButton;
    `