
import { extendTheme } from '@mui/joy/styles';


declare module '@mui/joy/styles' {
  interface PaletteBackgroundOverrides {
    appcolor: true;
    secondaryColor: true
  }
}

const theme = extendTheme({
  "colorSchemes": {
    "light": {
      "palette": {
        "primary": {
          // 50: "#F5F5F9",
          "100": "#EBECF2",
          "200": "#CCCFDF",
          "300": "#ADB1CC",
          "400": "#7077A5",
          "500": "#333D7F",
          "600": "#2E3772",
          "700": "#1F254C",
          "800": "#171B39",
          "900": "#0F1226",
        },
        "background": {
          "appcolor": "#333D79FF",
          "secondaryColor": "#F8F8FF",
        }
      }
    },
    "dark": {
      "palette": {
        "primary": {
          // 900: "#F5F5F9",
          "900": "#EBECF2",
          "800": "#CCCFDF",
          "700": "#ADB1CC",
          "600": "#7077A5",
          "500": "#333D7F",
          "400": "#2E3772",
          "300": "#1F254C",
          "200": "#171B39",
          "100": "#0F1226",
        },
        "background": {
          "appcolor": "#333D79FF",
          "secondaryColor": "#F8F8FF",
        }
      }
    }
  }
})


export default theme;