import React, { useEffect, useState } from "react";
import { Typography, Box, List, ListItem, ListItemContent, Chip, ColorPaletteProp, ListDivider, Sheet, Table, iconButtonClasses, Button, IconButton, Card, Input, ButtonGroup, Option, Dropdown, MenuButton, Menu, FormControl, Select, MenuItem, Checkbox, Tooltip } from "@mui/joy";
import { toast } from 'react-toastify';
import { LoadingView, PageTitle } from "../../../components";
import { formatDate } from "../../../utils";

import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import agentDT from "../../../assets/fakeData/customerData.json"
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { BlockOutlined, CalendarMonthOutlined, ContentCopyRounded, DeleteOutline, Download, DownloadOutlined, EditOutlined, FilterAltOutlined, VisibilityOutlined, WarningRounded } from "@mui/icons-material";
import { STATUS } from "../../../constant";
import { activeReferenceReset, createReferenceRequest, createReferenceReset, referenceListRequest, referenceListReset, viewReferenceRequest, viewReferenceReset } from "../../../store/actions";
import { NAVIGATE_TO_VIEWREFERENCEPAGE } from "../../../route/types";
import { useTranslation } from "react-i18next";

const MobileViewTable = ({ data, props }) => {
    const {t} = useTranslation()
    return (
        <Box sx={{ display: { xs: 'block', md: 'none' }, }}>
            {data.map((listItem, index) => (
                <List
                    key={index}
                    size="sm"
                    sx={{
                        '--ListItem-paddingX': 0,
                    }}
                >
                    <ListItem
                        variant="outlined"
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'start',
                            borderRadius: 10,
                            backgroundColor: 'background.popup',
                            p: 1
                        }}
                    >
                        <ListItemContent sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
                            {/* <ListItemDecorator>
                                  <Avatar size="sm">{listItem.customer.initial}</Avatar>
                              </ListItemDecorator> */}
                            <div>
                                <Typography fontWeight={600} gutterBottom>{listItem.tokenRef}</Typography>
                                {/* <Typography level="body-xs" gutterBottom>
                                    <b>Finger:</b>
                                    {{
                                        "L2": "Left Index",
                                        "L3": "Left Middle",
                                        "L4": "Left Ring",
                                        "L5": "Left Little",
                                        "R2": "Right Index",
                                        "R3": "Right Middle",
                                        "R4": "Right Ring",
                                        "R5": "Right Little",
                                        "L1": "Left Thumb",
                                        "R1": "Right Thumb"
                                    }[listItem.fingerCode]}
                                </Typography> */}
                                {/* <Typography level="body-xs" gutterBottom><b>Format: </b>{listItem.format}</Typography> */}
                                <Typography level="body-xs" gutterBottom><b>{t("reference.header.createdAt")}:</b> {formatDate(listItem.createdAt)}</Typography>
                                <Typography level="body-xs" gutterBottom><b>{t("reference.header.updatedAt")}:</b> {formatDate(listItem.updatedAt)}</Typography>
                            </div>
                        </ListItemContent>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            rowGap: 1
                        }}>
                            <Chip
                                variant="solid"
                                size="sm"
                                color={
                                    {
                                        2: 'success',
                                        3: 'neutral',
                                        Cancelled: 'danger',
                                    }[listItem.status] as ColorPaletteProp
                                }
                            >
                                {{ 2: "Not Used", 3: "Used" }[listItem.status]}
                            </Chip>
                            <ButtonGroup variant="soft" size="sm">
                                <Button color="success" onClick={() => props.view(listItem.tokenRef)}>
                                    <VisibilityOutlined />
                                </Button>
                                {/* <Button color="primary" onClick={() => props.download(listItem.data)}>
                                    <Download />
                                </Button> */}
                            </ButtonGroup>
                        </Box>

                    </ListItem>
                    {/* <ListDivider /> */}
                </List>
            ))}
        </Box>
    )
}

const DesktopViewTable = ({ data, props }) => {
    const {t} = useTranslation()
    return (
        <React.Fragment>
            <Sheet
                className="OrderTableContainer"
                variant="plain"
                sx={{
                    display: { xs: 'none', md: 'flex', lg: 'flex' },
                    // width: '60%',
                    borderRadius: 'sm',
                    flexShrink: 1,
                    overflow: 'auto',
                    minHeight: 0,
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    hoverRow
                    // stripe='even'
                    sx={{
                        // '--TableCell-headBackground': 'var(--joy-palette-primary-500)',
                        '--Table-headerUnderlineThickness': '1px',
                        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                        '--TableCell-paddingY': '4px',
                        '--TableCell-paddingX': '8px',
                        '& th': {
                            // color: 'white',
                            borderTop: "4px solid var(--joy-palette-primary-500)",
                            // borderColor: 'primary.500'
                        },
                        '& tr > *:last-child': {
                            position: 'sticky',
                            right: 0,
                            // bgcolor: 'var(--joy-palette-background-level1)',
                        },
                    }}
                >
                    <thead>
                        <tr style={{ textAlign: 'center' }}>
                            <th style={{ padding: '10px 6px' }}>{t("reference.header.reference")}</th>
                            {/* <th style={{ padding: '10px 6px' }}>Finger</th>
                            <th style={{ padding: '10px 6px' }}>Format</th> */}
                            <th style={{ padding: '10px 6px' }}>{t("reference.header.createdAt")}</th>
                            <th style={{ padding: '10px 6px' }}>{t("reference.header.updatedAt")}</th>
                            <th style={{ padding: '10px 6px' }}>{t("reference.header.status")}</th>
                            <th aria-label="last" style={{ padding: '10px 6px' }}>{t("reference.header.actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <tr key={index}>
                                <td>
                                    <Typography level="body-sm">{row.tokenRef}</Typography>
                                </td>
                                {/* <td>
                                    <Typography level="body-sm">{
                                        {
                                            "L2": "Left Index",
                                            "L3": "Left Middle",
                                            "L4": "Left Ring",
                                            "L5": "Left Little",
                                            "R2": "Right Index",
                                            "R3": "Right Middle",
                                            "R4": "Right Ring",
                                            "R5": "Right Little",
                                            "L1": "Left Thumb",
                                            "R1": "Right Thumb"
                                        }[row.fingerCode]}
                                    </Typography>
                                </td> */}
                                {/* <td>
                                    <Typography level="body-sm">{row.format}</Typography>
                                </td> */}
                                <td>
                                    <Typography level="body-sm">{formatDate(row.createdAt)}</Typography>
                                </td>
                                <td>
                                    <Typography level="body-sm">{formatDate(row.updatedAt)}</Typography>
                                </td>
                                <td>
                                    <Chip
                                        variant="solid"
                                        size="sm"
                                        color={
                                            {
                                                3: 'success',
                                                2: 'neutral',
                                            }[row.status] as ColorPaletteProp
                                        }
                                    >
                                        {{ 2: "Not Used", 3: "Used" }[row.status]}
                                    </Chip>
                                </td>
                                <td>
                                    <ButtonGroup variant="soft" size="sm">
                                        <Button color="neutral" onClick={() => props.view(row.tokenRef)}>
                                            <VisibilityOutlined />
                                        </Button>
                                        {/* <Button color="primary" onClick={() => props.download(row.data)}>
                                            <Download />
                                        </Button> */}
                                    </ButtonGroup>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Sheet>
        </React.Fragment>
    );
}

const TokenCreationPage = ({
    loginResult,
    accessToken,

    createReferenceStatus,
    createReferenceResult,
    createReferenceErrorMessage,

    activeReferenceStatus,
    activeReferenceResult,
    activeReferenceErrorMessage,

    referenceListStatus,
    referenceListResult,
    referenceListErrorMessage,

    viewReferenceStatus,
    viewReferenceErrorMessage,
    viewReferenceResult
}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const isDesktop = useMediaQuery("(min-width:600px)");

    // const [agentData, setAgentData] = useState(agentDT)
    const [fingerData, setFingerData] = useState([])

    const [finger, setFinger] = useState('');
    const [format, setFormat] = useState([]);
    const [payload, setPayload] = useState([])
    // let token_ref: string = 'Ahjjeuad-h126had-13gdahda-hdahhda';
    const [token_ref, setTokenRef] = useState('')
    const [copied, setCopied] = useState(false);

    /* -------- FUNCTION TO SEARCH ------ */
    const [search, setSearch] = useState('');
    const [filterBy, setFilterBy] = useState('name')
    const [isSearchVisible, setIsSearchVisible] = useState(false);

    useEffect(() => {
        if (createReferenceStatus === STATUS.SUCCESS) {
            toast.success(t("reference.success"))
            setPayload([])
            setTokenRef(createReferenceResult.reference)
            dispatch(createReferenceReset());
        }
        else if (createReferenceStatus === STATUS.ERROR) {
            toast.error(createReferenceErrorMessage);
            dispatch(createReferenceReset());
        }

        if (referenceListStatus === STATUS.SUCCESS) {
            setFingerData(referenceListResult.message);
            // dispatch(referenceListReset())
        }
        else if (referenceListStatus === STATUS.ERROR) {
            toast.error(referenceListErrorMessage);
            setFingerData([])
            dispatch(referenceListReset())
        }

        if (viewReferenceStatus === STATUS.SUCCESS) {
            navigate(NAVIGATE_TO_VIEWREFERENCEPAGE, {
                state: {
                    details: viewReferenceResult
                }
            })
            dispatch(viewReferenceReset());
        }
        else if (viewReferenceStatus === STATUS.ERROR) {
            toast.error(viewReferenceErrorMessage);
            dispatch(viewReferenceReset());
        }
    }, [createReferenceStatus, referenceListStatus, viewReferenceStatus])

    /* ------ Fetch reference history ------ */
    useEffect(() => {
        dispatch(referenceListRequest(accessToken, { "user_id": loginResult.message.userId }))
    }, [])

    // filtre by  name
    const filteredData = fingerData.filter((item) => {
        if (filterBy === 'reference') {
            return item.tokenRef.toLowerCase().includes(search.toLowerCase())
        }
        else if (filterBy === 'date') {
            return item.created_at.includes(search)
        }
        else {
            return item.tokenRef.toLowerCase().includes(search.toLowerCase())
        }
    })


    /* ------ pagination functions ------- */
    const [currentPage, setCurrentPage] = useState(0);
    const ITEMS_PER_PAGE = 20;
    const pageLength = Math.ceil(filteredData.length / ITEMS_PER_PAGE)

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(0, prevPage - 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, pageLength - 1));
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginatedData = filteredData.slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE);
    /* ---- end of pagination functions ------- */


    /* ---------- Token Form functions ------------- */
    const handleSelectFinger = (event, newValue) => {
        console.log('Finger Selected: ', newValue);
        setFinger(newValue)
    }

    const handleSelectFormat = (event, newValue) => {
        console.log('Format Selected: ', newValue);
        setFormat(newValue)
    }

    const AddFinger = () => {
        if (finger === '' || format.length < 1) {
            toast.warn(t("reference.emptyWarn"))
        }
        else if (payload.some(item => item.finger === finger) && payload.length > 0) {
            toast.warn(t("reference.readyWarn"))
        }
        else {
            setPayload([...payload, {
                "finger": finger,
                "format": format
            }])
            // console.log("Payloads data of finger: ", payload)
            setTimeout(() => {
                setFinger("");
                setFormat([]);
            }, 1000)
        }

    }

    const deleteFinger = (index) => {
        const newPayload = [...payload];
        newPayload.splice(index, 1);
        setPayload(newPayload);
    };

    const createReference = () => {
        if (payload.length > 0) {
            const data = []
            payload.forEach((item) => {
                item.format.forEach((f) => {
                    data.push({
                        "fingerCode": item.finger,
                        "format": f,
                        "data": "",
                        "jpgData": ""
                    })
                })
            })

            // console.log("Sended data: ", data)
            dispatch(createReferenceRequest(accessToken, {
                "id": "",
                "reeference": "",
                "user_id": loginResult.message.userId,
                "payload": data
            }))
        }
    }

    /* --------- Function to handle copy of token reference ----- */
    const handleCopy = () => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(token_ref).then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 4000); // Reset copied state after 2 seconds
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        } else {
            // Fallback method for unsupported browsers
            const textArea = document.createElement("textarea");
            textArea.value = token_ref;
            textArea.style.position = "fixed";  // Avoid scrolling to bottom
            textArea.style.left = "-9999px";    // Move it out of viewport
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand('copy');
                setCopied(true);
                setTimeout(() => setCopied(false), 4000);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textArea);
        }
    };

    const viewReference = (tokenRef) => {
        dispatch(viewReferenceRequest(accessToken, { "token_ref": tokenRef }));
        // () => null
        // toast("View me 👀")
    }

    const check_loading = () => {
        if (createReferenceStatus === STATUS.LOADING
            || referenceListStatus === STATUS.LOADING
            || viewReferenceStatus === STATUS.LOADING
        ) {
            return true
        } else {
            return false
        }
    }
    return (
        <Box>
            <PageTitle title={t("reference.title")} />
            <LoadingView loading={check_loading()} />

            {/* ---- CREATE REFERENCE FORM ----- */}
            <Typography level="title-md">{t("reference.create")}</Typography>
            <Typography level="body-sm">{t("reference.createDesc")}</Typography>
            <Sheet
                component='form'
                onSubmit={null}
                sx={{
                    display: 'flex',
                    my: 1,
                    mb: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    backgroundColor: 'transparent',
                    // width: { xs: 'auto', md: '30%' },
                    justifyContent: 'flex-start',
                }}>
                <FormControl required>
                    <Select
                        // defaultValue={finger}
                        value={finger}
                        placeholder={t("reference.selectFinger")}
                        // multiple
                        onChange={handleSelectFinger}
                        sx={{
                            minWidth: '16rem',
                            // width: '200px'
                        }}
                        slotProps={{
                            listbox: {
                                sx: {
                                    width: '100%',
                                },
                            },
                        }}
                    >
                        <Option value="L1">{t("reference.fingerCode.L1")}</Option>
                        <Option value="L2">{t("reference.fingerCode.L2")}</Option>
                        <Option value="L3">{t("reference.fingerCode.L3")}</Option>
                        <Option value="L4">{t("reference.fingerCode.L4")}</Option>
                        <Option value="L5">{t("reference.fingerCode.L5")}</Option>
                        <Option value="R1">{t("reference.fingerCode.R1")}</Option>
                        <Option value="R2">{t("reference.fingerCode.R2")}</Option>
                        <Option value="R3">{t("reference.fingerCode.R3")}</Option>
                        <Option value="R4">{t("reference.fingerCode.R4")}</Option>
                        <Option value="R5">{t("reference.fingerCode.R5")}</Option>
                    </Select>
                </FormControl>

                <FormControl required>
                    <Select
                        // defaultValue={format}
                        value={format}
                        placeholder={t("reference.selectFormat")}
                        multiple
                        onChange={handleSelectFormat}
                        sx={{
                            minWidth: '16rem',
                            // width: '100px'
                        }}
                        slotProps={{
                            listbox: {
                                sx: {
                                    width: '100%',
                                },
                            },
                        }}
                    >
                        <Option value="jpg"><Checkbox checked={format.includes('jpg')} label="JPEG" /></Option>
                        <Option value="iso"><Checkbox checked={format.includes('iso')} label="ISO" /></Option>
                        <Option value="wsq"><Checkbox checked={format.includes('wsq')} label="WSQ" /></Option>
                    </Select>
                </FormControl>

                <Button onClick={AddFinger}>{t("reference.addBtn")}</Button>
            </Sheet>

            {/* ---- REFERENCE LIST ----- */}
            {payload.length > 0 &&
                <Sheet
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2, backgroundColor: 'transparent',
                        width: { xs: '100%', md: '60%' },
                        mb: 4
                    }}>
                    <Typography level="title-sm">{t("reference.referenceList")}</Typography>

                    <Table
                        aria-label="basic table"
                        variant="outlined"
                        sx={{
                            backgroundColor: 'background.popup',
                            // width: {xs: 'auto',md: '60%'}
                        }}>
                        <thead>
                            <tr>
                                <th>{t("reference.header.finger")}</th>
                                <th>{t("reference.header.format")}</th>
                                <th>{t("reference.header.delete")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {payload.map((item, index) => (
                                <tr key={index}>
                                    <td>{
                                        {
                                            "L2": t("reference.fingerCode.L2"),
                                            "L3": t("reference.fingerCode.L3"),
                                            "L4": t("reference.fingerCode.L4"),
                                            "L5": t("reference.fingerCode.L5"),
                                            "R2": t("reference.fingerCode.R2"),
                                            "R3": t("reference.fingerCode.R3"),
                                            "R4": t("reference.fingerCode.R4"),
                                            "R5": t("reference.fingerCode.R5"),
                                            "L1": t("reference.fingerCode.L1"),
                                            "R1": t("reference.fingerCode.R1")
                                        }[item.finger]}</td>
                                    <td>{item.format.map((f, id) => (<Typography key={id}>- {f}</Typography>))}</td>
                                    <td>
                                        <Button
                                            variant="plain"
                                            onClick={() => deleteFinger(index)}
                                        >
                                            {<DeleteOutline />}
                                        </Button>
                                    </td>

                                </tr>))}
                        </tbody>
                    </Table>

                    <Button onClick={createReference}>{t("reference.createBtn")}</Button>
                </Sheet>}

            {/* ----- ACTIVE REFERENCE ----- */}
            {token_ref && <>
                <Typography level="title-md">{t("reference.yourRef")}</Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: 'background.level2',
                    width: { xs: 'auto', md: '60%' },
                    p: 1,
                    mb: 4,
                    borderRadius: 6
                }}>
                    <Typography level="body-md" sx={{ fontFamily: 'Roboto Mono Variable, monospace', }}>
                        {token_ref}
                    </Typography>
                    <Tooltip title={copied ? 'Copied!' : 'Copy to clipboard'} variant="outlined" sx={{ alignSelf: 'flex-end' }}>
                        <Button onClick={handleCopy} variant="plain" sx={{ fontSize: 12, color: 'text.secondary' }}>
                            <ContentCopyRounded /> {copied ? 'Copied' : 'Copy'}
                        </Button>
                    </Tooltip>
                </Box>

            </>}
            {/* search */}
            <Sheet
                className="SearchAndFilters"
                sx={{
                    display: 'flex',
                    my: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    backgroundColor: 'transparent',
                    // width: { xs: 'auto', md: '60%' },
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="title-md">{t("reference.referenceHist")}</Typography>
                {fingerData.length > 0 &&
                    <Input
                        size="sm"
                        placeholder={t("reference.search")+" "+ filterBy}
                        type={{ date: 'date', reference: "text", address: 'text', mobile: 'number' }[filterBy]}
                        defaultValue={search}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        startDecorator={filterBy === 'date' ? <CalendarMonthOutlined /> : <SearchIcon />}
                        endDecorator={
                            <Dropdown>
                                <MenuButton variant="plain">
                                    {/* <Typography level="body-sm">Filter</Typography> */}
                                    <FilterAltOutlined />
                                </MenuButton>
                                <Menu sx={{ minWidth: '120px', fontSize: '14px' }}>
                                    <MenuItem onClick={() => setFilterBy('name')} selected={filterBy == "reference"}>{t("reference.header.reference")}</MenuItem>
                                    <MenuItem onClick={() => setFilterBy('date')} selected={filterBy == "date"}>{t("reference.header.createdAt")}</MenuItem>
                                </Menu>
                            </Dropdown>
                        }
                        sx={{ width: { xs: 'auto', md: '30%' }, textTransform: 'capitalize' }}
                    />}
            </Sheet>

            {/* ---- LIST OF PREVIOUS REFERENCES ----- */}
            {fingerData.length > 0 ? <>
                {/* ------ render different view depend on plafform -------- */}
                <MobileViewTable data={paginatedData} props={{ view: viewReference, download: () => null }} />
                <DesktopViewTable data={paginatedData} props={{ view: viewReference, download: () => null }} />

                {/* Pagination */}
                {fingerData.length > ITEMS_PER_PAGE
                    &&
                    <Box
                        className="Pagination-laptopUp"
                        sx={{
                            pt: 2,
                            gap: 1,
                            [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
                            display: 'flex'
                        }}
                    >
                        <Button
                            size="sm"
                            variant="outlined"
                            color="neutral"
                            startDecorator={<KeyboardArrowLeftIcon />}
                            onClick={handlePreviousPage}
                            disabled={currentPage === 0}
                        >
                            {isDesktop ? 'Previous' : ""}
                        </Button>


                        <Box sx={{ flex: 1 }} />
                        {/* for desktop to display page number */}
                        {Array.from({ length: pageLength }).map((_, page) => (
                            <IconButton
                                key={page}
                                size="sm"
                                variant={'outlined'}
                                color="neutral"
                                onClick={() => handlePageChange(page)}
                                disabled={currentPage === page}
                                sx={{ display: { xs: 'none', md: 'flex' } }}
                            >
                                {page + 1}
                            </IconButton>
                        ))}

                        {/* for mobile to display page number */}
                        <Typography level="body-sm" mx="auto" textAlign={'center'} sx={{ display: { xs: 'flex', md: 'none' } }}>
                            Page {currentPage + 1} of {pageLength}
                        </Typography>
                        <Box sx={{ flex: 1 }} />



                        <Button
                            size="sm"
                            variant="outlined"
                            color="neutral"
                            endDecorator={<KeyboardArrowRightIcon />}
                            onClick={handleNextPage}
                            disabled={currentPage === pageLength - 1}
                        >
                            {isDesktop ? 'Next' : ""}
                        </Button>
                    </Box>}

            </> :
                <Card sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: '20%'
                }}>
                    <WarningRounded sx={{ fontSize: '50px', color: 'background.appcolor' }} />
                    <Typography level="title-md">{t("reference.emptyData.header")}</Typography>
                    <Typography level="body-sm" textAlign={'center'}>{t("reference.emptyData.desc")}</Typography>
                </Card>
            }
        </Box>
    )
}

const mapStateToProps = ({ auth, reference }) => {
    const { loginResult: loginResult, accessToken } = auth
    const {
        createReferenceStatus: createReferenceStatus,
        createReferenceResult: createReferenceResult,
        createReferenceErrorMessage: createReferenceErrorMessage,

        activeReferenceStatus: activeReferenceStatus,
        activeReferenceResult: activeReferenceResult,
        activeReferenceErrorMessage: activeReferenceErrorMessage,

        referenceListStatus: referenceListStatus,
        referenceListResult: referenceListResult,
        referenceListErrorMessage: referenceListErrorMessage,

        viewReferenceStatus: viewReferenceStatus,
        viewReferenceResult: viewReferenceResult,
        viewReferenceErrorMessage: viewReferenceErrorMessage
    } = reference

    return {
        loginResult,
        accessToken,

        createReferenceStatus,
        createReferenceResult,
        createReferenceErrorMessage,

        activeReferenceStatus,
        activeReferenceResult,
        activeReferenceErrorMessage,

        referenceListStatus,
        referenceListResult,
        referenceListErrorMessage,

        viewReferenceStatus,
        viewReferenceErrorMessage,
        viewReferenceResult
    }
}

export default connect(mapStateToProps, {})(TokenCreationPage)