import { call, put, takeLatest } from 'redux-saga/effects';
import { FINGERSCAN } from "../../constant";
import errorMessage from '../../utils/error.json';
import { doCreateReference, doFetchActiveReference, doFetchReferenceList, doViewReference } from '../../service/reference';


function* createReferenceTask(action) {
    try {
        yield put({ type: FINGERSCAN.CREATE_REFERENCE_LOADING });

        const { payload } = action;

        const res = yield call(doCreateReference, payload.token, payload.data);

        if (res.status == 200) {
            yield put({
                type: FINGERSCAN.CREATE_REFERENCE_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.statusCode] : res.message = errorMessage[1000];
            yield put({
                type: FINGERSCAN.CREATE_REFERENCE_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.statusCode] : errorMessage[4000];
        yield put({
            type: FINGERSCAN.CREATE_REFERENCE_FAILURE,
            payload: errMsg
        })
    }
}

function* activeReferenceTask(action) {
    try {
        yield put({ type: FINGERSCAN.ACTIVE_REFERENCE_LOADING });

        const { payload } = action;

        const res = yield call(doFetchActiveReference, payload.token, payload.data);

        if (res.status == 200) {
            yield put({
                type: FINGERSCAN.ACTIVE_REFERENCE_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.statusCode] : res.message = errorMessage[1000];
            yield put({
                type: FINGERSCAN.ACTIVE_REFERENCE_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.statusCode] : errorMessage[4000];
        yield put({
            type: FINGERSCAN.ACTIVE_REFERENCE_FAILURE,
            payload: errMsg
        })
    }
}

function* referenceListTask(action) {
    try {
        yield put({ type: FINGERSCAN.REFERENCE_LIST_LOADING });

        const { payload } = action;

        const res = yield call(doFetchReferenceList, payload.token, payload.data);

        if (res.status == 200) {
            yield put({
                type: FINGERSCAN.REFERENCE_LIST_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.statusCode] : res.message = errorMessage[1000];
            yield put({
                type: FINGERSCAN.REFERENCE_LIST_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.statusCode] : errorMessage[4000];
        yield put({
            type: FINGERSCAN.REFERENCE_LIST_FAILURE,
            payload: errMsg
        })
    }
}

function* viewReferenceTask(action) {
    try {
        yield put({ type: FINGERSCAN.VIEW_REFERENCE_LOADING });

        const { payload } = action;

        const res = yield call(doViewReference, payload.token, payload.data);

        if (res.status == 200) {
            yield put({
                type: FINGERSCAN.VIEW_REFERENCE_SUCCESS,
                payload: res.data
            })
        } else {
            res.data ? res.message = errorMessage[res.data.statusCode] : res.message = errorMessage[1000];
            yield put({
                type: FINGERSCAN.VIEW_REFERENCE_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage[e.statusCode] : errorMessage[4000];
        yield put({
            type: FINGERSCAN.VIEW_REFERENCE_FAILURE,
            payload: errMsg
        })
    }
}

function* referenceSaga() {
    yield takeLatest(FINGERSCAN.CREATE_REFERENCE_REQUEST, createReferenceTask);
    yield takeLatest(FINGERSCAN.ACTIVE_REFERENCE_REQUEST, activeReferenceTask);
    yield takeLatest(FINGERSCAN.REFERENCE_LIST_REQUEST, referenceListTask);
    yield takeLatest(FINGERSCAN.VIEW_REFERENCE_REQUEST, viewReferenceTask);
}

export default referenceSaga;