import React, { Fragment, useEffect, useState } from "react";
import { Typography, Box, Sheet, FormControl, Select, Option, Checkbox, Button, Table, Input, FormLabel, Card } from "@mui/joy";
import { toast } from 'react-toastify';
import { LoadingView, Main, PageTitle } from "../../../components";
import { connect, useDispatch } from "react-redux";
import { apiRequest, apiReset, viewApiRequest, viewApiReset } from "../../../store/actions";
import { DeleteOutline, EditAttributes, EditRounded, InfoOutlined, WarningRounded } from "@mui/icons-material";
import { STATUS } from "../../../constant";
import { NAVIGATE_TO_SUPPORTPAGE } from "../../../route/types";
import { useTranslation } from "react-i18next";

const ApiAccessPage = ({
    loginResult,
    accessToken,

    apiStatus,
    apiResult,
    apiErrorMessage,

    viewApiStatus,
    viewApiResult,
    viewApiErrorMessage
}) => {

    const dispatch = useDispatch();
    const { t } = useTranslation()

    const [finger, setFinger] = useState('');
    const [format, setFormat] = useState([]);
    const [payload, setPayload] = useState([])
    const [callbackUrl, setCallbackUrl] = useState("");

    const [api_url, setApiUrl] = useState('');
    const [client_id, setClientID] = useState('');
    const [secret_key, setSecretKey] = useState('');
    const [api_payload, setApiPayload] = useState([]);

    const [newCallback, setNewCallback] = useState(false);

    useEffect(() => {
        if (apiStatus === STATUS.SUCCESS) {
            toast.success(t("api.success"))
            setPayload([])
            setCallbackUrl('')
            dispatch(viewApiRequest(
                accessToken, { "userId": loginResult.message.userId }))
            dispatch(apiReset())
        }
        else if (apiStatus === STATUS.ERROR) {
            toast.error(apiErrorMessage);
            dispatch(apiReset())
        }

        if (viewApiStatus === STATUS.SUCCESS) {
            const incomingData = viewApiResult.message;
            // const incomingData = [
            //     {
            //         "fingerCode": "R1",
            //         "format": "wsq"
            //     },
            //     {
            //         "fingerCode": "L3",
            //         "format": "wsq"
            //     },
            //     {
            //         "fingerCode": "L3",
            //         "format": "jpg"
            //     },
            //     {
            //         "fingerCode": "R1",
            //         "format": "iso"
            //     }
            // ];

            const targetArray = [];

            incomingData.forEach(item => {
                const existingEntry = targetArray.find(entry => entry.fingerCode === item.fingerCode);

                if (existingEntry) {
                    // If the format is not already in the array, add it
                    if (!existingEntry.format.includes(item.format)) {
                        existingEntry.format.push(item.format);
                    }
                } else {
                    // Create a new entry with fingerCode and format as an array
                    targetArray.push({
                        fingerCode: item.fingerCode,
                        format: [item.format]
                    });
                }
            });

            console.log(targetArray);

            setNewCallback(false)
            setApiUrl(viewApiResult.callback_url)
            setClientID(viewApiResult.clientID)
            setSecretKey(viewApiResult.secretKey)
            setApiPayload(targetArray)
        }
        else if (viewApiStatus === STATUS.ERROR) {
            toast.error(viewApiErrorMessage);
            if (viewApiErrorMessage == 'No Data found') {
                setNewCallback(true)
            }
            dispatch(viewApiReset());
        }
    }, [apiStatus, viewApiStatus])

    useEffect(() => {
        dispatch(viewApiRequest(
            accessToken, { "userId": loginResult.message.userId }))
    }, [])

    /* ---------- Token Form functions ------------- */
    const handleSelectFinger = (event, newValue) => {
        console.log('Finger Selected: ', newValue);
        setFinger(newValue)
    }

    const handleSelectFormat = (event, newValue) => {
        console.log('Format Selected: ', newValue);
        setFormat(newValue)
    }

    const AddFinger = () => {
        if (!finger && format.length < 1) {
            toast.warn(t("api.emptyWarn"))
        }
        else if (payload.some(item => item.finger === finger) && payload.length > 0) {
            toast.warn(t("readyWarn"))
        }
        else if (callbackUrl === "") {
            toast.warn("Your callback URL needed")
        }
        else {
            setPayload([...payload, {
                "finger": finger,
                "format": format
            }])
            // console.log("Payloads data of finger: ", payload)
            setTimeout(() => {
                setFinger("");
                setFormat([]);
            }, 1000)
        }

    }

    const deleteFinger = (index) => {
        const newPayload = [...payload];
        newPayload.splice(index, 1);
        setPayload(newPayload);
    };

    const createApiCall = () => {
        if (payload.length > 0) {
            const data = []
            payload.forEach((item) => {
                item.format.forEach((f) => {
                    data.push({
                        "fingerCode": item.finger,
                        "format": f,
                        "data": "",
                        "jpgData": ""
                    })
                })
            })

            // console.log("Sended data: ", data)
            dispatch(apiRequest(accessToken, {
                // "id": "",
                "callbackUrl": callbackUrl,
                "userId": loginResult.message.userId,
                "payload": data
            }))
        }
    }

    const check_loading = () => {
        if (apiStatus === STATUS.LOADING || viewApiStatus === STATUS.LOADING) {
            return true
        } else {
            return false
        }
    }


    return (
        <Box>
            <PageTitle title={t("api.title")} />
            <LoadingView loading={check_loading()} />

            <Typography
                level="body-md"
                variant="soft"
                color="danger"
                startDecorator={<InfoOutlined />}
                sx={{
                    // fontFamily: 'Roboto Mono Variable', 
                    px: 1,
                    py: 0.8,
                    mb: 2,
                    borderRadius: 'sm',
                    // backgroundColor: 'danger' 
                }}
            >
                {t("api.desc") + " "} <a href={NAVIGATE_TO_SUPPORTPAGE}> Support page</a>
            </Typography>

            {/* ----- CREATE NEW CALLBACK URL ----- */}
            {newCallback &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'space-between',
                        gap: 2
                    }}>
                    <Sheet
                        component='form'
                        onSubmit={null}
                        variant="outlined"
                        color="primary"
                        sx={{
                            display: 'flex',
                            my: 1,
                            mb: 1,
                            gap: 1,
                            p: 1,
                            borderRadius: 'sm',
                            flexDirection: { xs: 'column', sm: 'column' },
                            backgroundColor: 'background.popup',
                            width: { xs: 'auto', md: '45%' },
                            justifyContent: 'flex-start',
                        }}>
                        <Typography level="title-md">{t("api.createApi")}</Typography>
                        <Typography level="body-sm">{t("api.createApiDesc")}</Typography>
                        <FormControl required>
                            <FormLabel>{t("api.callbackUrl")}</FormLabel>
                            <Input
                                // color="primary"
                                value={callbackUrl}
                                placeholder={t("api.callbackUrlPlaceholder")}
                                onChange={(event) => setCallbackUrl(event.target.value)} />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t("api.finger")}</FormLabel>
                            <Select
                                // color="primary"
                                value={finger}
                                placeholder={t("api.selectFinger")}
                                // multiple
                                onChange={handleSelectFinger}
                                sx={{
                                    minWidth: '16rem',
                                    // width: '200px'
                                }}
                                slotProps={{
                                    listbox: {
                                        sx: {
                                            width: '100%',
                                        },
                                    },
                                }}
                            >
                                <Option value="L1">{t("api.fingerCode.L1")}</Option>
                                <Option value="L2">{t("api.fingerCode.L2")}</Option>
                                <Option value="L3">{t("api.fingerCode.L3")}</Option>
                                <Option value="L4">{t("api.fingerCode.L4")}</Option>
                                <Option value="L5">{t("api.fingerCode.L5")}</Option>
                                <Option value="R1">{t("api.fingerCode.R1")}</Option>
                                <Option value="R2">{t("api.fingerCode.R2")}</Option>
                                <Option value="R3">{t("api.fingerCode.R3")}</Option>
                                <Option value="R4">{t("api.fingerCode.R4")}</Option>
                                <Option value="R5">{t("api.fingerCode.R5")}</Option>
                            </Select>
                        </FormControl>

                        <FormControl required>
                            <FormLabel>{t("api.format")}</FormLabel>
                            <Select
                                // color="primary"
                                value={format}
                                placeholder={t("api.selectFormat")}
                                multiple
                                onChange={handleSelectFormat}
                                sx={{
                                    minWidth: '16rem',
                                    // width: '100px'
                                }}
                                slotProps={{
                                    listbox: {
                                        sx: {
                                            width: '100%',
                                        },
                                    },
                                }}
                            >
                                <Option value="jpg"><Checkbox checked={format.includes('jpg')} label="JPEG" /></Option>
                                <Option value="iso"><Checkbox checked={format.includes('iso')} label="ISO" /></Option>
                                <Option value="wsq"><Checkbox checked={format.includes('wsq')} label="WSQ" /></Option>
                            </Select>
                        </FormControl>

                        <Button onClick={AddFinger}>{t("api.addBtn")}</Button>
                    </Sheet>

                    {/* ---- REFERENCE LIST ----- */}
                    {payload.length > 0 &&
                        <Sheet
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2, backgroundColor: 'transparent',
                                width: { xs: '100%', md: '55%' },
                                mb: 4
                            }}>
                            <Typography level="title-sm">{t("api.callbackUrl")}</Typography>
                            <Typography level="body-md" sx={{ fontFamily: 'Roboto Mono Variable, monospace', p: 1, backgroundColor: 'background.level3' }}>
                                {callbackUrl ? callbackUrl : "Your call back url needed."}
                            </Typography>

                            <Typography level="title-sm">{t("api.selected")}</Typography>

                            <Table
                                aria-label="basic table"
                                variant="outlined"
                                sx={{
                                    backgroundColor: 'background.popup',
                                    // width: {xs: 'auto',md: '60%'}
                                }}>
                                <thead>
                                    <tr>
                                        <th>{t("api.header.finger")}</th>
                                        <th>{t("api.header.format")}</th>
                                        <th>{t("api.header.delete")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {payload.map((item, index) => (
                                        <tr key={index}>
                                            <td>{
                                                {
                                                    "L2": t("api.fingerCode.L2"),
                                                    "L3": t("api.fingerCode.L3"),
                                                    "L4": t("api.fingerCode.L4"),
                                                    "L5": t("api.fingerCode.L5"),
                                                    "R2": t("api.fingerCode.R2"),
                                                    "R3": t("api.fingerCode.R3"),
                                                    "R4": t("api.fingerCode.R4"),
                                                    "R5": t("api.fingerCode.R5"),
                                                    "L1": t("api.fingerCode.L1"),
                                                    "R1": t("api.fingerCode.R1")
                                                }[item.finger]}</td>
                                            <td>{item.format.map((f, id) => (<Typography key={id}>- {f}</Typography>))}</td>
                                            <td>
                                                <Button
                                                    variant="plain"
                                                    onClick={() => deleteFinger(index)}
                                                >
                                                    {<DeleteOutline />}
                                                </Button>
                                            </td>

                                        </tr>))}
                                </tbody>
                            </Table>

                            <Button onClick={createApiCall}>{t("api.submit")}</Button>
                        </Sheet>}
                </Box>
            }

            {/* ----- CALLBACK URL DETAILS ----- */}
            {(api_payload.length > 0 && api_url) ?
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 2,
                        // justifyContent: 'space-between',
                        gap: 2
                    }}>
                    <Box sx={{ gap: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography level="title-md">{t("api.apiDetails")}</Typography>
                        <Button
                            startDecorator={<EditRounded />}
                            onClick={() => setNewCallback(true)}>
                            {t("api.changeBtn")}
                        </Button>
                    </Box>

                    <Box sx={{ gap: 1 }}>
                        <Typography level="title-sm">{t("api.clientId")}</Typography>
                        <Typography level="body-md" sx={{ fontFamily: 'Roboto Mono Variable, monospace', p: 1, backgroundColor: 'background.level3' }}>
                            {client_id || "No client ID"}
                        </Typography>
                    </Box>

                    <Box sx={{ gap: 1 }}>
                        <Typography level="title-sm">{t("api.secreteKey")}</Typography>
                        <Typography level="body-md" sx={{ fontFamily: 'Roboto Mono Variable, monospace', p: 1, backgroundColor: 'background.level3' }}>
                            {secret_key || "No secret key"}
                        </Typography>
                    </Box>

                    <Box sx={{ gap: 1 }}>
                        <Typography level="title-sm">{t("api.yourApi")}</Typography>
                        <Typography level="body-md" sx={{ fontFamily: 'Roboto Mono Variable, monospace', p: 1, backgroundColor: 'background.level3' }}>
                            {api_url || "No callback url"}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography level="title-sm">{t("api.fingerAndFormat")}</Typography>
                        <Table
                            aria-label="basic table"
                            variant="outlined"
                            sx={{
                                backgroundColor: 'background.popup',
                                // width: {xs: 'auto',md: '60%'}
                            }}>
                            <thead>
                                <tr>
                                    <th>{t("api.header.finger")}</th>
                                    <th>{t("api.header.format")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {api_payload.map((item, index) => (
                                    <tr key={index}>
                                        <td>{
                                            {
                                                "L2": t("api.fingerCode.L2"),
                                                "L3": t("api.fingerCode.L3"),
                                                "L4": t("api.fingerCode.L4"),
                                                "L5": t("api.fingerCode.L5"),
                                                "R2": t("api.fingerCode.R2"),
                                                "R3": t("api.fingerCode.R3"),
                                                "R4": t("api.fingerCode.R4"),
                                                "R5": t("api.fingerCode.R5"),
                                                "L1": t("api.fingerCode.L1"),
                                                "R1": t("api.fingerCode.R1")
                                            }[item.fingerCode]}</td>
                                        <td>{item.format.map((f, id) => (<Typography key={id}>- {f}</Typography>))}</td>
                                    </tr>))}
                            </tbody>
                        </Table>
                    </Box>
                </Box>
                :
                <Card sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: '20%'
                }}>
                    <WarningRounded sx={{ fontSize: '50px', color: 'background.appcolor' }} />
                    <Typography level="title-md">{t("api.emptyData.header")}</Typography>
                    <Typography level="body-sm" textAlign={'center'}>{t("api.emptyData.desc")}</Typography>
                </Card>
            }
        </Box>
    )
}

const mapStateToProps = ({ auth, integration }) => {
    const { loginResult: loginResult, accessToken } = auth
    const {
        apiStatus: apiStatus,
        apiResult: apiResult,
        apiErrorMessage: apiErrorMessage,

        viewApiStatus: viewApiStatus,
        viewApiResult: viewApiResult,
        viewApiErrorMessage: viewApiErrorMessage
    } = integration

    return {
        loginResult,
        accessToken,

        apiStatus,
        apiResult,
        apiErrorMessage,

        viewApiStatus,
        viewApiResult,
        viewApiErrorMessage
    }
}

export default connect(mapStateToProps, {})(ApiAccessPage)