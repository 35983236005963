import React, { Fragment, useEffect, useState } from "react";
import { Typography, Box, Card, Grid, Avatar, Button, CardActions, Divider, List, ListItem, ListItemDecorator, Sheet, ButtonGroup, Table, Chip, ColorPaletteProp } from "@mui/joy";
import { toast } from 'react-toastify';
import { Main, PageTitle } from "../../../components";
import { Check, CheckCircle, CheckCircleOutline, DiamondOutlined, EditNoteOutlined, Folder, GifBoxOutlined, GroupOutlined, KeyboardArrowRight, LinkOutlined, PaidOutlined, Person, RedeemOutlined, VisibilityOutlined } from "@mui/icons-material";
import { NAVIGATE_TO_APIACCESSPAGE, NAVIGATE_TO_PACKAGEPAGE, NAVIGATE_TO_LOGSPAGE, NAVIGATE_TO_TRANSACTIONHISTORYPAGE, NAVIGATE_TO_PROFILEPAGE, NAVIGATE_TO_TOKENPAGE } from "../../../route/types";
import { Link, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import image from "../../../constant/image";
import SwipeableViews from 'react-swipeable-views';
import { STATUS } from "../../../constant";
import { packageDetailsRequest, referenceListRequest } from "../../../store/actions";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../utils";
import { CardContent } from "@mui/material";


// ----- TOP CARD COMPONENTS ------ //
const TopCards = ({props}) => {

    const { t } = useTranslation()
    const cardsItems = [
        {
            title: t("dashboard.summary.createTitle"),
            desc: t("dashboard.summary.createDesc"),
            icon: <EditNoteOutlined sx={{ color: 'black', fontSize: '30px' }} />,
            BgColor: 'background.appcolor',
            btnCaption: t("dashboard.summary.createBtn"),
            action: NAVIGATE_TO_TOKENPAGE
        },
        {
            title: t("dashboard.summary.packageTitle") + ' Free',
            desc: t("dashboard.summary.unit") + props.unit + '\n' + t("dashboard.summary.packageDesc") + props.expire,
            icon: <RedeemOutlined sx={{ color: 'black', fontSize: '30px' }} />,
            BgColor: 'black',
            btnCaption: t("dashboard.summary.packageBtn"),
            action: NAVIGATE_TO_PACKAGEPAGE
        },
        {
            title: t("dashboard.summary.integrationTitle"),
            desc: t("dashboard.summary.integrationDesc"),
            icon: <LinkOutlined sx={{ color: 'black', fontSize: '30px' }} />,
            BgColor: 'darkgreen',
            btnCaption: t("dashboard.summary.integrationBtn"),
            action: null
            // NAVIGATE_TO_APIACCESSPAGE
        }
    ]

    return (
        <Grid container
            spacing={{ xs: 1, md: 1 }}
            columns={{ xs: 4, sm: 8, md: 10 }}
            sx={{ flexGrow: 1 }}>
            {cardsItems.map((item, index) => (
                <Grid xs={2} sm={3} md={3} key={index}>
                    <Link to={item.action} style={{ textDecoration: 'none' }}>
                        <Card
                            variant='soft'
                            sx={{
                                backgroundColor: item.BgColor,
                                boxShadow: 'sm',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                justifyContent: 'space-between'
                            }}>
                            <Box>
                                <Card
                                    variant="soft"
                                    size="sm"
                                    sx={{
                                        backgroundColor: 'white',
                                        width: '50px',
                                        height: '50px',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                    {item.icon}
                                </Card>
                                <Typography level="title-sm" sx={{ color: 'white', pt: 1 }}>{item.title}</Typography>
                                <Typography
                                    level="body-xs"
                                    sx={{
                                        color: 'white',
                                        mt: 2,
                                        whiteSpace: 'pre-wrap', // Ensures line breaks are preserved
                                        wordBreak: 'break-word', // Prevents long strings from breaking the layout
                                    }} >
                                    {item.desc}
                                </Typography>
                            </Box>

                            <Button
                                size="sm"
                                onClick={() => <Link to={item.action} />}
                                sx={{
                                    backgroundColor: 'white',
                                    color: 'black',
                                }}
                            >
                                {item.btnCaption}
                            </Button>
                        </Card>
                    </Link>
                </Grid>))
            }
        </Grid >
    )
}
// ----- SLIDE VIEW PACKEGE COMPONENT ----- //
const PackageCards = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const packageDetails = [
        // {
        //     name: 'Free',
        //     color: '#CE8946',
        //     items: ['Up to 5 scan units per year', 'Access to basic features and user-friendly interface'],
        //     price: 'Free'
        // },
        {
            name: 'Bronze',
            color: '#CE8946',
            items: ['Up to 20 scan units per year', 'All features of the Free package'],
            price: '$10'
        },
        {
            name: 'Silver',
            color: '#C0C0C0',
            items: ['Up to 50 scan units per year', 'All features of the Bronze package'],
            price: '$20'
        },
        {
            name: 'Gold',
            color: '#FFD700',
            items: ['Up to 300 scan units per month', 'All features of the Silver package'],
            price: '$100'
        },
        {
            name: 'Platinum',
            color: '#B9F2FF',
            items: ['Up to 1,000 scan units per month', 'System integration support'],
            price: '$300'
        }
    ]

    const [currentIndex, setCurrentIndex] = useState(0);

    // Auto slide every 3 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % packageDetails.length);
        }, 3000); // Change the slide every 3 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    const handleChangeIndex = (index) => {
        setCurrentIndex(index);
    };

    return (
        <SwipeableViews
            index={currentIndex}
            onChangeIndex={handleChangeIndex}
            enableMouseEvents
        // style={{maxWidth: 400,  margin: 'auto',}}
        >
            {packageDetails.map((item, index) => (
                <Card
                    // size="md"
                    variant="plain"
                    key={index}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // backgroundColor: 'pink'
                        boxShadow: 'md'
                    }}>
                    <Typography level="title-lg" color="success" sx={{ textAlign: 'center', p: 0 }}>{item.name}</Typography>
                    <Typography level="title-sm" sx={{ textAlign: 'center', p: 0 }}>
                        <Typography level="h2">{item.price}</Typography>{item.price != 'Free' && '/mo'}
                    </Typography>

                    <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
                        {item.items.map((list, id) => (
                            <ListItem key={id}>
                                <ListItemDecorator>
                                    <CheckCircleOutline />
                                </ListItemDecorator>
                                <Typography level="body-xs">{list}</Typography>
                            </ListItem>))}
                    </List>

                    <CardActions sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', p: 0 }}>
                        <Button
                            variant="solid"
                            color="primary"
                            // endDecorator={<KeyboardArrowRight />}
                            fullWidth
                            onClick={() => navigate(NAVIGATE_TO_PACKAGEPAGE)}
                        >
                            {t("dashboard.viewMore")}
                        </Button>
                    </CardActions>
                </Card>
            ))}
        </SwipeableViews>
    )
}

// ----- REFERENCE HISTORY TABLE --- //
const ReferenceTable = ({ data, props }) => {
    const { t } = useTranslation()
    return (
        <React.Fragment>
            <Sheet
                className="OrderTableContainer"
                variant="plain"
                sx={{
                    display: { xs: 'none', md: 'flex', lg: 'flex' },
                    borderRadius: 'sm',
                    flexShrink: 1,
                    overflow: 'auto',
                    minHeight: 0,
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    hoverRow
                >
                    <thead>
                        <tr style={{ textAlign: 'center' }}>
                            <th style={{ padding: '10px 6px' }}>{t("reference.header.reference")}</th>
                            <th style={{ padding: '10px 6px' }}>{t("reference.header.createdAt")}</th>
                            <th style={{ padding: '10px 6px' }}>{t("reference.header.updatedAt")}</th>
                            <th style={{ padding: '10px 6px' }}>{t("reference.header.status")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <tr key={index}>
                                <td><Typography level="body-xs">{row.tokenRef}</Typography></td>
                                <td><Typography level="body-xs">{formatDate(row.createdAt)}</Typography></td>
                                <td><Typography level="body-xs">{formatDate(row.updatedAt)}</Typography></td>
                                <td>
                                    <Chip
                                        variant="soft"
                                        size="sm"
                                        color={
                                            {
                                                3: 'success',
                                                2: 'neutral',
                                            }[row.status] as ColorPaletteProp
                                        }
                                    >
                                        {{ 2: "Not Used", 3: "Used" }[row.status]}
                                    </Chip>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Sheet>
        </React.Fragment>
    );
}

//.... ------- MAIN COMPONENT ------ ....//
const Dashboard = ({
    accessToken,
    loginResult,
    loginStatus,
    referenceListStatus,
    referenceListResult,
    packageDetailsStatus,
    packageDetailsResult,
}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation()

    const [username, setUserName] = useState('');
    const [useremail, setUserEmail] = useState('');
    const [useraddress, setUserAddress] = useState('');

    const [packageunit, setPackageUnit] =  useState('...');
    const [packageexpire, setpackageExpire] = useState('...');

    const [referenceList, setReferenceList] = useState([]);

    const advertiseItems = [
        {
            title: t("dashboard.platform.mobileApp"),
            desc: t("dashboard.platform.mobileDesc"),
            link: 'https://play.google.com/store/apps/details?id=net.fingerscan.tbridge',
            source: image.Images.playstore
        },
        {
            title: t("dashboard.platform.website"),
            desc: t("dashboard.platform.websiteDesc"),
            link: 'https://fingerscan.net',
            source: image.Images.webportal
        },
    ]

    useEffect(() => {
        if (loginStatus === STATUS.SUCCESS) {
            setUserName(loginResult.message.name);
            setUserEmail(loginResult.message.email);
            setUserAddress('Dar es Salaam, Tanzania')
        }

        if (referenceListStatus === STATUS.SUCCESS) {
            let ref = referenceListResult.message;
            setReferenceList(ref.slice(0, 3))
        }

        if (packageDetailsStatus === STATUS.SUCCESS){
            setPackageUnit(packageDetailsResult.message.unit_balance);
            setpackageExpire(packageDetailsResult.message.expire_at);
        }
    }, [loginStatus, referenceListStatus, packageDetailsStatus])

    /* ------ Fetch reference history ------ */
    useEffect(() => {
        dispatch(referenceListRequest(accessToken, { "user_id": loginResult.message.userId }))
        dispatch(packageDetailsRequest(accessToken, { "userId": loginResult.message.userId }))
    }, [])

    // Get the current hour
    const currentHour = new Date().getHours();

    // Determine the greeting based on the hour
    const getGreeting = () => {
        if (currentHour >= 5 && currentHour < 12) {
            return `🌤️ ${t("dashboard.greeting.morning")}`;
        } else if (currentHour >= 12 && currentHour < 16) {
            return `☀️ ${t("dashboard.greeting.afternoon")}`;
        } else if (currentHour >= 16 && currentHour < 22) {
            return `⛅️ ${t("dashboard.greeting.evening")}`;
        } else {
            return `🌙 ${t("dashboard.greeting.night")}`;
        }
    };


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                // minHeight: '100dvh',
                gap: 1
            }}>

            {/* ... LEFT SIDE ... */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: { xs: 'auto', md: '75%' },
                    gap: 2,
                }}>
                <PageTitle title={getGreeting() + ', ' + username.split(" ")[0]} />

                {/* top card component */}
                <TopCards props={{unit: packageunit, expire: packageexpire || "..." }} />

                {/* middle */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column-reverse', md: 'row' },
                        gap: 2
                    }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: { xs: 'auto', md: '65%' },
                        gap: 1
                    }}>

                        {/* Platforms links */}
                        <Typography level="title-sm">{t("dashboard.platform.title")}</Typography>
                        {advertiseItems.map((item, index) => (
                            <Card
                                key={index}
                                variant="outlined"
                                sx={{
                                    borderRadius: 'md',
                                    display: 'flex',
                                    p: 0.5
                                }}
                            >
                                <CardContent sx={{ display: 'flex', gap: 1, justifyContent: 'space-between' }}>
                                    <Box>
                                        <Typography level="title-sm">{item.title}</Typography>
                                        <Typography level="body-xs">{item.desc}</Typography>
                                    </Box>

                                    <Link to={item.link} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                                        <img src={item.source} style={{ width: '120px' }} />
                                    </Link>
                                </CardContent>
                            </Card>))}
                    </Box>

                    {/* Package slide container */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: { xs: 'auto', md: '35%' },
                            gap: 1
                        }}>
                        <Typography level="title-sm">{t("dashboard.package")}</Typography>
                        <PackageCards />
                    </Box>
                </Box>

                {/* reference table */}
                {referenceList.length > 0 &&
                    <Box sx={{
                        display: { xs: 'none', md: 'flex' },
                        flexDirection: 'column',
                        gap: 1
                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography level="title-sm">{t("dashboard.referenceHist")}</Typography>
                            <Link to={NAVIGATE_TO_TOKENPAGE} style={{ textDecoration: 'none' }}>
                                <Typography level="title-sm" color="primary">{t("dashboard.viewAll")}</Typography>
                            </Link>
                        </Box>

                        <ReferenceTable data={referenceList} props={null} />
                    </Box>}

            </Box>

            {/* ... --------------------------------------------------------------------------- ... */}

            {/* ... RIGHT SIDE ... */}
            <Box
                sx={{
                    display: { xs: 'none', md: 'flex' },
                    flexDirection: 'column',
                    width: '25%',
                    // minHeight: '100dvh',
                    borderRadius: 'sm',
                    p: 1,
                    pt: 4,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    backgroundColor: 'background.surface',
                    gap: 1
                }}>
                <Typography level="title-lg">{t("dashboard.myDetails")}</Typography>

                <Avatar size="lg" sx={{ width: '100px', height: '100px' }} />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    <Typography level="title-md">{username}</Typography>
                    <Typography level="body-sm">{useremail}</Typography>
                    <Typography level="body-sm">{useraddress}</Typography>
                </Box>
                <Button variant="solid" color="success" size="sm" onClick={() => navigate(NAVIGATE_TO_PROFILEPAGE)}>{t("dashboard.viewProfile")}</Button>
            </Box>
        </Box>
    )
}

const mapStateToProps = ({ auth, reference, payments }) => {
    const {
        loginStatus,
        loginResult,
        accessToken
    } = auth

    const {
        packageDetailsStatus,
        packageDetailsResult,
        packageDetailsErrorMessage
    } = payments

    const {
        referenceListStatus: referenceListStatus,
        referenceListResult: referenceListResult,
        referenceListErrorMessage: referenceListErrorMessage,
    } = reference

    return {
        loginResult,
        loginStatus,
        accessToken,
        packageDetailsStatus,
        packageDetailsResult,

        referenceListStatus,
        referenceListResult
    }
}

export default connect(mapStateToProps, {})(Dashboard)