import React, { Fragment, useEffect, useState } from "react";
import { Typography, Box, Card, Chip, Divider, List, ListItem, ListItemDecorator, CardActions, Button, Grid, CardContent, Avatar, Table, Modal, ModalDialog, DialogTitle, DialogContent, Stack, FormControl, FormLabel, Input, Textarea } from "@mui/joy";
import { toast } from 'react-toastify';
import { LoadingView, Main, PageTitle } from "../../../components";
import { Check, CheckCircleOutlineRounded, KeyboardArrowRight, RedeemOutlined } from "@mui/icons-material";
import { formatDate } from "../../../utils";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { STATUS } from "../../../constant";
import { packageDetailsRequest, packagesDetailsReset } from "../../../store/actions";
import { useNavigate } from "react-router-dom";
import { NAVIGATE_TO_PAYMENTREQUESTPAGE } from "../../../route/types";

const PackagePage = ({
    loginResult,
    loginStatus,
    accessToken,
    packageDetailsStatus,
    packageDetailsResult,
    packageDetailsErrorMessage
}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [unit, setUnit] = useState('...');
    const [packageName, setPackageName] = useState('...');
    const [duration, setDuration] = useState('...');
    const [created, setCreated] = useState('');
    const [expire, setExpire] = useState('');
    const [packageStatus, setPackageStatus] = useState("...");

    const [openModal, setOpenModal] = useState(false)

    // Packages prices details
    const packageDetails = [
        {
            name: t("package.bronzeTitle"),
            des: t("package.bronzeDesc"),
            color: '#CE8946',
            price: { month: '$ 10.00', year: '$ 108.00' },
            items: [t("package.bronzeFeatures.1"), t("package.bronzeFeatures.2")]
        },
        {
            name: t("package.silverTitle"),
            des: t("package.silverDesc"),
            color: '#C0C0C0',
            price: { month: '$ 20.00', year: '$ 216.00' },
            items: [t("package.silverFeatures.1"), t("package.silverFeatures.2")]
        },
        {
            name: t("package.goldTitle"),
            des: t("package.goldDesc"),
            color: '#FFD700',
            price: { month: '$ 100.00', year: '$ 960.00' },
            items: [t("package.goldFeatures.1"), t("package.goldFeatures.2"), t("package.goldFeatures.3")]
        },
        {
            name: t("package.platinumTitle"),
            des: t("package.platinumDesc"),
            color: '#FFD700',
            price: { month: '$ 300.00', year: '$ 2880.00' },
            items: [t("package.platinumFeatures.1"), t("package.platinumFeatures.2"), t("package.platinumFeatures.4"), t("package.platinumFeatures.3")]
        },
        {
            name: t("package.proTitle"),
            des: t("package.proDesc"),
            color: '#FFD700',
            price: { month: '$ 300.00', year: '$ 3600.00' },
            items: [t("package.proFeatures.1"), t("package.proFeatures.2"), t("package.proFeatures.3")]
        }
    ]

    // Current package details
    const currentPackageDetails = [
        { title: t("package.packageName"), desc: packageName },
        { title: t("package.packageStatus"), desc: { 2: 'Active' }[packageStatus] },
        { title: t("package.packageUnit"), desc: unit },
        // { title: 'Duration', desc: duration + " months" },
        { title: t("package.packageDate"), desc: formatDate(created) || '...' },
        { title: t("package.packageEnd"), desc: formatDate(expire) || '...' }
    ]

    useEffect(() => {
        if (packageDetailsStatus === STATUS.SUCCESS) {
            setUnit(packageDetailsResult.message.unit_balance);
            setPackageName(packageDetailsResult.message.package_name);
            // setDuration(packageDetailsStatus.message.);
            setPackageStatus(packageDetailsResult.message.status);
            setCreated(packageDetailsResult.message.subscribe_at);
            setExpire(packageDetailsResult.message.expire_at);
        } else if (packageDetailsStatus === STATUS.ERROR) {
            toast.error(packageDetailsErrorMessage)
            dispatch(packagesDetailsReset())
        }
    }, [packageDetailsStatus])

    useEffect(() => {
        dispatch(packageDetailsRequest(accessToken, { "userId": loginResult.message.userId }))
    }, [])

    const checkLoading = () => {
        if (packageDetailsStatus === STATUS.LOADING) {
            return true
        } else {
            return false
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <PageTitle title={t("package.title")} />
            <LoadingView loading={checkLoading()} />

            {/* Current package details */}
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',

                }}>

                <CardContent>
                    <Divider sx={{ '--Divider-childPosition': '0%', gap: 1 }}>
                        <Avatar size="lg" sx={{ backgroundColor: 'text.primary' }}>
                            <RedeemOutlined sx={{ color: 'background.body' }} />
                        </Avatar>
                        <Typography level="title-md">{t("package.currentPackage")}</Typography>
                    </Divider>
                    <Table
                        borderAxis="none"
                        sx={{
                            pl: 3,
                            "--TableCell-paddingY": "2px",
                            "--TableCell-height": "2px"
                        }}>
                        <tbody>
                            {currentPackageDetails.map((item, index) => (
                                <tr key={index} style={{ padding: 0 }}>
                                    <td style={{ width: '200px' }}><b>{item.title}</b></td>
                                    <td>{item.desc}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardContent>
            </Card>

            {/* ... Package Price ... */}
            <Divider sx={{ pt: 1 }}>
                <Typography level="title-sm">{t("package.packagePrice")}</Typography>
            </Divider>

            <Grid container
                spacing={{ xs: 1, md: 1 }}
                columns={{ xs: 4, sm: 8, md: 10 }}
                sx={{ flexGrow: 1, justifyContent: 'center' }}>
                {packageDetails.map((item, index) => (
                    <Grid xs={2} sm={3} md={3} key={index}>
                        <Card
                            variant="outlined"
                            key={index}
                            sx={{
                                // background:
                                //     'linear-gradient(to top, var(--joy-palette-primary-300), rgba(0,0,0,0) 200px), linear-gradient(to top, var(--joy-palette-primary-200), rgba(0,0,0,0) 300px)',
                                boxShadow: 'md',
                                borderRadius: 0,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',

                            }}>
                            <Typography level="h2">{item.name}</Typography>
                            {item.name !== "Professional" && <Box>
                                <Typography level="h2" sx={{ mr: 'auto', textAlign: 'center' }}>
                                    {item.price.month}{''}
                                    {item.name !== "Free" && <Typography fontSize="sm" textColor="text.tertiary">
                                        / {t("package.month")}
                                    </Typography>}
                                </Typography>
                                {item.name !== "Free" && <Typography level="title-sm" sx={{ mr: 'auto', textAlign: 'center' }}>
                                    {item.price.year}{''}
                                    <Typography fontSize="sm" textColor="text.tertiary">
                                        / {t("package.year")}
                                    </Typography>
                                </Typography>}
                            </Box>}
                            <Typography level="body-sm" sx={{ textAlign: 'center' }}>{item.des}</Typography>
                            <Divider inset="none" />
                            <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
                                {item.items.map((list, id) => (
                                    <ListItem key={id}>
                                        <ListItemDecorator>
                                            <CheckCircleOutlineRounded />
                                        </ListItemDecorator>
                                        {list}
                                    </ListItem>))}
                            </List>
                            <Divider inset="none" />
                            <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>

                                <Button
                                    variant="outlined"
                                    color="neutral"
                                    onClick={() => {
                                        // item.name === 'Professional' ?
                                        //     setOpenModal(true)
                                        //     : navigate(NAVIGATE_TO_PAYMENTREQUESTPAGE, {
                                        //         state: {
                                        //             package_data: item,
                                        //             have_company: true
                                        //         }
                                        //     })
                                    }}
                                >
                                    {item.name === 'Professional' ? t("package.contact") : t("package.getStarted")}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <ProfessionalRequestModal visible={openModal} close={() => setOpenModal(false)} />
        </Box >
    )
}

// .... MODAL FOR USER TO REQUEST PROFESSIONAL PACKAGE .... //
const ProfessionalRequestModal = ({ visible, close }) => {

    const [noScan, setNoScan] = useState("");
    const [additional, setAdditional] = useState('');

    return (
        <Modal open={visible} onClose={close}>
            <ModalDialog>
                <DialogTitle>Request for Professional Package</DialogTitle>
                <DialogContent>Fill in the information and we will get in touch with you.</DialogContent>
                <form
                    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        close();
                    }}
                >
                    <Stack spacing={2}>
                        <FormControl required>
                            <FormLabel>Number of Scan per Month</FormLabel>
                            <Input
                                autoFocus
                                value={noScan}
                                onChange={(e) => setNoScan(e.target.value)}
                                placeholder="Enter number of scans you want per month" />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>Additional features</FormLabel>
                            <Textarea
                                minRows={4}
                                value={additional}
                                onChange={(e) => setAdditional(e.target.value)}
                                placeholder="Suggest or request additional features you want" />
                        </FormControl>
                        <Button type="submit">Submit</Button>
                    </Stack>
                </form>
            </ModalDialog>
        </Modal>
    )
}

const mapStateToProps = ({ auth, reference, payments }) => {
    const {
        loginStatus,
        loginResult,
        accessToken
    } = auth

    const {
        packageDetailsStatus,
        packageDetailsResult,
        packageDetailsErrorMessage
    } = payments

    return {
        loginResult,
        loginStatus,
        accessToken,
        packageDetailsStatus,
        packageDetailsResult,
        packageDetailsErrorMessage
    }
}

export default connect(mapStateToProps, {})(PackagePage)