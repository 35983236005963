import React, { Fragment, useEffect } from "react";
import { Typography, Box, Sheet, Table, Button } from "@mui/joy";
import { toast } from 'react-toastify';
import { Main, PageTitle } from "../../../components";
import { useLocation } from "react-router-dom";
import image from "../../../constant/image";
import { Download, DownloadForOffline, DownloadForOfflineOutlined, FileDownload, SaveAltOutlined, SaveAsOutlined } from "@mui/icons-material";

const ViewReferencePage = () => {
    const { state } = useLocation();
    const { details } = state

    const handleExtract = (dt) => {
        // Your data to be saved into the file
        // const data = JSON.stringify(details.message,null,2);
        const data = `Finger: ${{
            "L2": "Left Index",
            "L3": "Left Middle",
            "L4": "Left Ring",
            "L5": "Left Little",
            "R2": "Right Index",
            "R3": "Right Middle",
            "R4": "Right Ring",
            "R5": "Right Little",
            "L1": "Left Thumb",
            "R1": "Right Thumb"
        }[dt.fingerCode]}
            \n-----------------------------------------------------------------------------------
            \n${dt.format} Data: ${dt.fingerData}\n----------------------------------------------------------------
            \nJPG base64: ${dt.jpgFingerData}`


        // Create a blob object with the data
        const blob = new Blob([data], { type: "text/plain" });

        // Generate a URL for the blob
        const url = URL.createObjectURL(blob);

        // Create a temporary <a> element
        const a = document.createElement("a");
        a.href = url;
        a.download = `${{
            "L2": "Left-Index",
            "L3": "Left-Middle",
            "L4": "Left-Ring",
            "L5": "Left-Little",
            "R2": "Right-Index",
            "R3": "Right-Middle",
            "R4": "Right-Ring",
            "R5": "Right-Little",
            "L1": "Left-Thumb",
            "R1": "Right-Thumb"
        }[dt.fingerCode]}-Data ${new Date().toDateString()}.txt`; // The name of the file to be downloaded

        // Append the <a> element to the DOM and trigger the download
        document.body.appendChild(a);
        a.click();

        // Clean up by removing the <a> element and revoking the blob URL
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const handleDownload = (dt) => {
        const link = document.createElement('a');
        link.href = `data:image/jpeg;base64,${dt.jpgFingerData}`;
        link.download =  `${{
            "L2": "Left-Index",
            "L3": "Left-Middle",
            "L4": "Left-Ring",
            "L5": "Left-Little",
            "R2": "Right-Index",
            "R3": "Right-Middle",
            "R4": "Right-Ring",
            "R5": "Right-Little",
            "L1": "Left-Thumb",
            "R1": "Right-Thumb"
        }[dt.fingerCode]}-image ${new Date().toDateString()}.jpg`; // file name for the downloaded image
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    return (
        <Box>
            <PageTitle title={'Reference ' + details.reference + ' Details'} />

            <Sheet
                variant="outlined"
                sx={{
                    width: { xs: 'auto', md: '60%' },
                    display: 'flex',
                    // width: '60%',
                    borderRadius: 'sm',
                    flexShrink: 1,
                    overflow: 'auto',
                    minHeight: 0,
                }}>
                <Table>
                    <thead>
                        <tr>
                            <th>Details</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Reference</td>
                            <td>{details.reference}</td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>Used</td>
                        </tr>
                        {details.message.map((dt, index) => (
                            <tr key={index} style={{ height: '50px' }}>
                                <td style={{ flexDirection: 'column', }}>
                                    <Typography>
                                        <b>Finger: </b>{{
                                            "L2": "Left Index",
                                            "L3": "Left Middle",
                                            "L4": "Left Ring",
                                            "L5": "Left Little",
                                            "R2": "Right Index",
                                            "R3": "Right Middle",
                                            "R4": "Right Ring",
                                            "R5": "Right Little",
                                            "L1": "Left Thumb",
                                            "R1": "Right Thumb"
                                        }[dt.fingerCode]}
                                    </Typography>
                                    <Typography>
                                        <b>Format: </b>
                                        {dt.format}
                                    </Typography>
                                </td>
                                <td>
                                    <Box sx={{
                                        display: 'flex',
                                        // flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        justifyContent: 'flex-start',
                                    }}>
                                        <img
                                            src={`data:image/jpeg;base64,${dt.jpgFingerData}`}
                                            style={{
                                                width: "110px",
                                                height: '130px',
                                                padding: '5px'
                                            }} />

                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: "flex-end", gap: 1 }}>
                                            <Button
                                                variant="soft"
                                                size="sm"
                                                color="success"
                                                title="Extract Data"
                                                startDecorator={<SaveAsOutlined />}
                                                onClick={() => handleExtract(dt)}>
                                                Extract Data
                                            </Button>

                                            <Button
                                                variant="soft"
                                                size="sm"
                                                title="Download Image"
                                                startDecorator={<DownloadForOfflineOutlined />}
                                                onClick={() => handleDownload(dt)}>
                                                Download Image
                                            </Button>
                                        </Box>
                                    </Box>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Sheet>
        </Box>





    )
}


export default ViewReferencePage